import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import reducer from 'redux/reducer';

import { createLocaleState } from 'redux/localeAction';
import { doRefreshLocale } from 'common/api';

function createAppStore() {
  const params = new URLSearchParams(window.location.search);
  if (params.get('locale')) {
    localStorage.setItem('locale', params.get('locale'));
  } else {
    const locale = localStorage.getItem('locale');

    if(locale === undefined || locale === null){
      localStorage.setItem('locale', 'en_US');
    }
  }
  const locale = createLocaleState(localStorage.getItem('locale'));
  doRefreshLocale(localStorage.getItem('locale'));
  const store = configureStore({
    reducer,
    preloadedState: {locale},
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
    devTools: process.env.NODE_ENV !== 'production',
  });
  return store;
}

export {
  createAppStore
};
