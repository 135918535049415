import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

const UI_STAGE_LOGIN = 'UI_STAGE_LOGIN';
const UI_STAGE_EXPIRATION = 'UI_STAGE_EXPIRATION';
const UI_STAGE_RESET = 'UI_STAGE_RESET';
const UI_STAGE_RESET_MESSAGE = 'UI_STAGE_RESET_MESSAGE';
const UI_DRAWER_SEND_CODE = 'UI_STAGE_SEND_CODE';
const UI_DRAWER_VERIFY_CODE = 'UI_STAGE_VERIFY_CODE';


function useStageError(path) {
  const state = useSelector(s => s.login);
  const error = React.useMemo(() => R.view(R.lensPath(['error',path]),state),[state,path]);
  return error;
}

export {
  UI_STAGE_RESET,
  UI_STAGE_LOGIN,
  UI_STAGE_EXPIRATION,
  UI_DRAWER_SEND_CODE,
  UI_DRAWER_VERIFY_CODE,
  UI_STAGE_RESET_MESSAGE,
  useStageError,
};
