import React from 'react';
import * as R from 'ramda';
import {
  useIntl,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  switchLanguage
} from 'redux/localeAction';
import {
  isEnv
} from 'common/tool';
/*
const languageOptions = [
  { key: 'de_CH', label: 'LANG_DE_CH' },
  { key: 'de_DE', label: 'LANG_DE_DE' },
//  { key: 'en_AU', label: 'LANG_EN_AU' },
  { key: 'en_EU', label: 'LANG_EN_EU' },
  { key: 'en_GB', label: 'LANG_EN_GB' },
  { key: 'en_US', label: 'LANG_EN_US' },
  { key: 'es_LA', label: 'LANG_ES_LA' },
  { key: 'es_MX', label: 'LANG_ES_MX' },
//  { key: 'fr_CA', label: 'LANG_FR_CA' },
  { key: 'fr_FR', label: 'LANG_FR_FR' },
  { key: 'zh_CN', label: 'LANG_ZH_CN' },
  { key: 'ja_JP', label: 'LANG_JA_JP' },
//  { key: 'pt_BR', label: 'LANG_PT_BR'  },
//  { key: 'sv_SE', label: 'LANG_SV_SE' },
//  { key: 'no_NO', label: 'LANG_NO_NO' },
  { key: 'ko_KR', label: 'LANG_KO_KR' },
];

*/

const envLang = () => {

  if(isEnv('qa2') ||
     isEnv('cloud') ||
     isEnv('cloudcd') ||
     isEnv('cloudqa') ||
     isEnv('sdm')) {
    return [
      { key: 'en_EU', label: 'LANG_EN_EU' },
      { key: 'en_US', label: 'LANG_EN_US' },
      { key: 'en_GB', label: 'LANG_EN_GB' },
      { key: 'de_DE', label: 'LANG_DE_DE' },
      { key: 'zh_CN', label: 'LANG_ZH_CN' },
      { key: 'fr_FR', label: 'LANG_FR_FR' },
      { key: 'ja_JP', label: 'LANG_JA_JP' },
      { key: 'es_LA', label: 'LANG_ES_LA' },
      { key: 'fr_CA', label: 'LANG_FR_CA' },
      { key: 'es_MX', label: 'LANG_ES_MX' },
      { key: 'de_CH', label: 'LANG_DE_CH' },
      { key: 'ko_KR', label: 'LANG_KO_KR' }
    ];
  }
  if (isEnv('sqaeu') ||
      isEnv('scdeu') ||
      isEnv('spdeu')) {
    return [
      { key: 'en_EU', label: 'LANG_EN_EU' },
      { key: 'en_US', label: 'LANG_EN_US' },
      { key: 'en_GB', label: 'LANG_EN_GB' },
      { key: 'de_DE', label: 'LANG_DE_DE' },
      { key: 'zh_CN', label: 'LANG_ZH_CN' },
      { key: 'fr_FR', label: 'LANG_FR_FR' },
      { key: 'ja_JP', label: 'LANG_JA_JP' },
      { key: 'es_LA', label: 'LANG_ES_LA' },
      { key: 'fr_CA', label: 'LANG_FR_CA' },
      { key: 'es_MX', label: 'LANG_ES_MX' },
      { key: 'de_CH', label: 'LANG_DE_CH' },
      { key: 'ko_KR', label: 'LANG_KO_KR' }
    ];
  }
  if(isEnv('scdqa') ||
     isEnv('spd') ||
     isEnv('scd') ||
     isEnv('sqa') ||
     isEnv('nooshauth')){
    return [
      { key: 'en_US', label: 'LANG_EN_US' },
      { key: 'en_GB', label: 'LANG_EN_GB' },
      { key: 'de_DE', label: 'LANG_DE_DE' },
      { key: 'en_EU', label: 'LANG_EN_EU' },
      { key: 'zh_CN', label: 'LANG_ZH_CN' },
      { key: 'fr_FR', label: 'LANG_FR_FR' },
      { key: 'de_CH', label: 'LANG_DE_CH' },
      { key: 'ko_KR', label: 'LANG_KO_KR' },
      { key: 'es_LA', label: 'LANG_ES_LA' },
      { key: 'ja_JP', label: 'LANG_JA_JP' },
      { key: 'es_MX', label: 'LANG_ES_MX' },
    ];
  }

  return [{ key: 'en_US', label: 'LANG_EN_US' },
          { key: 'zh_CN', label: 'LANG_ZH_CN' },];
};

const languageOptions = envLang();





function useLangugaeLogic(){
  const reduxDispatch = useDispatch();
  const language = useSelector(state => state.locale.language);
  const intl = useIntl();
  const languages = React.useMemo(
    () => R.map(
      R.over(R.lensProp('label'),v => intl.formatMessage({id: v,defaultMessage: v})),
      languageOptions),
    [intl]);
   const handleSwitchLanguage = React.useCallback(switchLanguage(reduxDispatch),[reduxDispatch]);

  return {
    language,
    languages,
    handleSwitchLanguage,
  };

}

export default useLangugaeLogic;

