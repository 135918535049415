import React from 'react';
import * as R from 'ramda';
import {
  doPreAuth
} from 'common/api';

import { authErrorCodeToIntl } from 'common/intl';

const auth = R.curry((handleSuccess,handleError, body) => {
  const loginURL = sessionStorage.getItem('loginURL');
  const locale = localStorage.getItem('locale');
  const back = sessionStorage.getItem('back');
  body.append('locale',locale);
  body.append('loginURL',loginURL);
  if(back !== null && back !== undefined){
    body.append('back',back);
  }
  return doPreAuth(body)
  .then(response => response.json()
    .then(json => ({ json, response })))
  .then(({ json, response }) => {
    if (response.ok) {
      handleSuccess({
        body,
        json,
        response
      });
    } else {
      const message = authErrorCodeToIntl(json.message);
      handleError({
        json,
        response,
        message
      });
    }
  }).catch((error) => {
    handleError({ error });
  })});

function usePreAuthLogic(handleSuccess,handleError) {
  return React.useCallback(auth(handleSuccess,handleError), [handleSuccess,handleError]);
}

export default usePreAuthLogic;
