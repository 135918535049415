import { combineReducers } from 'redux';

import {localeReducer} from './localeAction';
import {loginReducer} from './loginAction';
import {forgetUserIdReducer} from './forgetUserIdAction';
import {forgetPasswordReducer} from './forgetPasswordAction';

const reducer = combineReducers({
  locale: localeReducer,
  login: loginReducer,
  forgetUserId: forgetUserIdReducer,
  forgetPassword: forgetPasswordReducer,
});

export default reducer;
