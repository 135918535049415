import React from 'react';

const useCountDown = (seconds) => {
  const [timeLeft, setTimeLeft] = React.useState(seconds);
  React.useEffect(() => {
    if (timeLeft === 0) return;
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);

    return () => {
      if(intervalId){
        clearInterval(intervalId);
      }
    };
  }, [timeLeft]);
  return [timeLeft,setTimeLeft];
};

export default useCountDown;
