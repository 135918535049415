import React from 'react';

import * as R from 'ramda';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import useExpiredLogic from './expiredLogic';
import { useIntl } from 'react-intl';
import { UI_STAGE_EXPIRATION } from './loginUIStage';

function PasswordExpired(){
  const intl = useIntl();
  const {
    state,
    handleCancel,
    handleOpenReset
  } = useExpiredLogic();
  const [
    intlWarning,
    intlError,
    intlPasswordExpired,
    intlPleaseChangePassword,
    intlPleaseUpdatePassword,
    intlChangePassword,
    intlReturnToLogin,
    intlContinue
  ] = React.useMemo(
    () => R.map(
      id => intl.formatMessage({id}),
      ['WARNING',
       'ERROR',
       'PASSWORD_EXPIRED',
       'PLEASE_CHANGE_PASSWORD',
       'PLEASE_UPDATE_PASSWORD',
       'CHANGE_PASSWORD',
       'RETURN_TO_LOGIN',
       'CONTINUE'
      ]),[intl]);
  const [
    serverity,
    title,
    message,
    resetTitle,
    cancelTitle,
  ] = React.useMemo(() => {
    if (state.result.daysBeforePasswordExpiration <= 0) {
      return ['error',
              intlPasswordExpired,
              intlPleaseChangePassword,
              intlChangePassword,
              intlReturnToLogin
             ];
    }
    return ['warning',
            intl.formatMessage(
              {id: 'PASSWORD_EXPIRED_IN_DAYS'},
              { days: Math.ceil(state.result.daysBeforePasswordExpiration)}),
            intlPleaseUpdatePassword,
            intlChangePassword,
            intlContinue
           ];
  },[state,
     intl,
      intlWarning,
      intlError,
      intlPasswordExpired,
      intlPleaseChangePassword,
      intlPleaseUpdatePassword,
      intlChangePassword,
      intlReturnToLogin,
      intlContinue]);
  const loading = React.useMemo(() => {
    const stageLoading = R.view(R.lensPath(['loading', UI_STAGE_EXPIRATION]), state);
    if (stageLoading === undefined || stageLoading === null) {
      return false;
    }
    return stageLoading;
  }, [state]);
  if(state.expired === false || state.reset === true || state.mfa === true) return null;

  return (
    <Stack sx={{ with: '100%' }}>
      <Alert
        severity={serverity}
      >
        <AlertTitle>{title}</AlertTitle>
          {message}
      </Alert>
      <Box sx={{ mt: 4, width: '100%' }}>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          onClick={handleOpenReset}
        >
          {resetTitle}
        </Button>
      </Box>
      <Box sx={{ mt: 4, width: '100%' }}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          sx={{ width: '100%' }}
          variant="outlined"
          onClick={handleCancel}
        >
          {cancelTitle}
        </LoadingButton>
      </Box>
    </Stack>
  );
}

export default PasswordExpired;
