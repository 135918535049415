import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';

import useResetMessage from './resetMessageLogic';

function ResetMessage(){
  const {
    state,
    handleAuth
  } = useResetMessage();
  const intl = useIntl();
  const message = React.useMemo(() => {
    if(state.resetMessage === false) return null;
    const days = Math.ceil(state.result.daysBeforePasswordExpiration);
    return intl.formatMessage({id: 'RESET_PASSWORD_MESSAGE'},{days});
  },[state,intl]);
  
  if(state.resetMessage === false) return null;

  return (
    <Stack>
      <Alert severity="success">
        <AlertTitle>
          {intl.formatMessage({id: 'RESET_PASSWORD_MESSAGE_TITLE',default: 'RESET_PASSWORD_MESSAGE_TITLE'})}
        </AlertTitle>
        {message}
      </Alert>
      <Box sx={{mt: 6, width: '100%'}}>
        <Button
          sx={{width: '100%'}}
          variant="contained"
          onClick={handleAuth}
        >
          {intl.formatMessage({id: 'CONTINUE'})}
        </Button>
      </Box>
    </Stack>
  );
}

export default ResetMessage;
