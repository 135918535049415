import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import StandardLayout from 'components/layout/StandardLayout';
import ForgetUserIdTitle from 'components/forget/ForgetUserIdTitle';
import ForgetCommitted from 'components/forget/ForgetCommitted';
import ForgetUserIdForm from 'components/forget/ForgetUserIdForm';
import ForgetLink from 'components/layout/ForgetLink';
import {handleForgetUserIdReset} from 'redux/forgetUserIdAction';
import ForgetBox from 'components/forget/ForgetBox';
import TagManager from "@sooro-io/react-gtm-module";

function ForgetUserIdPage(){

  const REACT_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const GA_MEASUREMENT_ID = window.noosh.ga_measurement_id || REACT_GA_MEASUREMENT_ID;
  React.useLayoutEffect(() => {
    const openArgs = {
      dataLayer: {
        event: 'login_forget_user_page',
        eventName: 'login_forget_user_page',
      },
    };
    if(GA_MEASUREMENT_ID) {
      TagManager.dataLayer(openArgs);
    }
  },[GA_MEASUREMENT_ID]);

  const reduxDispatch = useDispatch();
  const state = useSelector(s => s.forgetUserId);
  if (state.success) {
    return (<ForgetBox store="forgetUserId">
              <ForgetCommitted
                onClick={() => handleForgetUserIdReset(reduxDispatch)}
                message={state.message}
              />
            </ForgetBox>);
  }
  return (<ForgetBox store="forgetUserId">
            <ForgetUserIdTitle />
            <ForgetUserIdForm />
            <ForgetLink/>
          </ForgetBox>);
}

export default ForgetUserIdPage;
