import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

function ForgetPasswordTitle() {
  const intl = useIntl();
  return (
    <Stack spacing={1} sx={{ mb: 4,width: '100%' }}>
      <Typography
        variant="h6"
        gutterBottom
        component="div">
        {intl.formatMessage({ id: 'FORGOT_YOUR_PASSWORD_TITLE' })}
      </Typography>
      <Typography
        gutterBottom
        component="div">
        {intl.formatMessage({ id: 'FORGOT_YOUR_PASSWORD_INFO' })}
      </Typography>
    </Stack>);
}

export default ForgetPasswordTitle;
