import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  doAutoLogin,
  doAutoRedirect,
  doFetchConfig,
  doRemeberMeCheck
} from 'common/api';

const renderLogin = () => {
  const portal = sessionStorage.getItem('portal');
  doFetchConfig(portal)
    .then(response => response.json()
      .then(json => ({ json, response })))
    .then(({ json, response }) => {
      sessionStorage.setItem('config', JSON.stringify(json));
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );
    })
    .catch(() => {
      sessionStorage.setItem('config', JSON.stringify({
        serviceName: 'Noosh'
      }));
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );
    });
};

function boostrapLogin() {

  /*
    https://nooshauth.qa2.noosh.com/login?persistentAutoLoginToken=353032383537343a63636363656166376137633030363266626133646438613465343034356230333a313634353531363931313734333a353031363836363a373230303030303a656e5f55533a2d31&back=https://one.qa2.noosh.com/nooshone/#?%24Qc2JSi4EoM05WsJ1uaEenUxXf414aU9Z&loginURL=https://qa2.noosh.com/noosh/home/login
  */

  const pathRegx = /\/(.*?)\/.*/;
  const m = window.location.pathname.match(pathRegx);
  if (m !== null && m.length === 2) {
    const portal = m[1];
    sessionStorage.setItem('portal', portal);
  } else {
    sessionStorage.setItem('portal', 'default');
  }

  const params = new URLSearchParams(window.location.search);
  if (params.get('loginURL')) {
    const loginURL = params.get('loginURL');
    sessionStorage.setItem('loginURL', loginURL);
  } else {
    sessionStorage.setItem('loginURL', window.location.href);
  }
  if (params.get('mode')) {
    const failReason = params.get('failReason');
    const mode = params.get('mode');
    sessionStorage.setItem('mode', mode);
    sessionStorage.setItem('failReason', failReason);
  } else {
    sessionStorage.removeItem('mode');
    sessionStorage.removeItem('failReason');
  }
  const autoLogin = params.get('autoLogin');

  if (params.get('persistentAutoLoginToken')) {
    doAutoLogin(window.location.search)
      .then(response => response.json()
        .then(json => ({ json, response })))
      .then(({ json, response }) => {
        const portal = sessionStorage.getItem('portal');
        if (response.ok) {
          const redirectUrl = json.redirectURL;
          window.location.href = redirectUrl;
        } else {
          params.delete('autoLogin');
          window.location.search = new URLSearchParams(params).toString();
          window.location.href = `${portal}/login`;
        }
      })
      .catch((error) => {
        params.delete('autoLogin');
        const portal = sessionStorage.getItem('portal');
        window.location.search = new URLSearchParams(params).toString();
        window.location.href = `${portal}/login`;
      });
  } else {
    let back = null;
    if (params.get('back')) {
      back = params.get('back');
      sessionStorage.setItem('back', back);
    } else {
      sessionStorage.removeItem('back');
    }
    const loginURL = sessionStorage.getItem('loginURL');
    if (autoLogin === "true" || autoLogin === true) {
      doAutoRedirect({ back, loginURL })
        .then((response) => {
          if (response.ok) {
            response.json()
              .then((json) => {
                const redirectUrl = json.redirectURL;
                window.location.href = redirectUrl;
              });
          } else {
            renderLogin();
          }
        })
        .catch((error) => {
          renderLogin();
        });
    } else {
      doRemeberMeCheck()
        .then((response) => {
          if (response.rememberMe) {
            var back = params.get("back");
            var locale = params.get("locale");
            var loginURL = params.get("loginURL");
            doAutoRedirect({ back, loginURL, locale})
              .then((response) => {
                if (response.ok) {
                  response.json()
                    .then((json) => {
                      const redirectUrl = json.redirectURL;
                      window.location.href = redirectUrl;
                    });
                } else {
                  renderLogin();
                }
              })
              .catch((error) => {
                renderLogin();
              });

          }else {
            renderLogin();
          }
        }).catch((error)=>{
          renderLogin();
        });
    }
  }


}

export default boostrapLogin;
