import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import {
  loadLanguage
} from 'redux/localeAction';
import { doRefreshLocale } from 'common/api';

function Intl(props){
  const {children} = props;
  const reduxDispatch = useDispatch();
  const localeState = useSelector(state => state.locale);
  const {
    language,
    locale,
    ready,
    messages,
  } = localeState;

  React.useEffect(() => {
    if(ready === false) {
      loadLanguage(reduxDispatch,language);
      doRefreshLocale(language);
      localStorage.setItem('locale',language);
    }
  },[reduxDispatch,language,ready]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export default Intl;
