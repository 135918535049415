import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useIntl} from 'react-intl';

function ForgetUserIdTitle(){
  const intl = useIntl();
  return (
    <Stack spacing={1} sx={{mb: 5}}>
      <Typography
        variant="h6"
        gutterBottom
        component="div">
        {intl.formatMessage({ id: 'FORGOT_YOUR_USER_ID_TITLE' })}
      </Typography>
      <Typography
        gutterBottom
        component="div">
        {intl.formatMessage({ id: 'FORGOT_YOUR_USER_ID_INFO' })}
      </Typography>
    </Stack>);
}

export default ForgetUserIdTitle;
