import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  handleLoginHandleResult,
  handleLoginCleanResult,
  handleLoginUpdateField,
  handleLoginShowAlert,
  handleLoginUIStage,
  handleLoginPageLoading,
} from 'redux/loginAction';

import useAuthLogic from './authLogic';
import { UI_STAGE_EXPIRATION } from './loginUIStage';
import { useIntl } from 'react-intl';

const buildAuthBody = (state) => {
  const authBody = new URLSearchParams();
  authBody.append('j_username', state.account);
  authBody.append('j_password', state.password);
  authBody.append('remember-me', state.remember);
  if (state.mfaCode !== undefined && state.mfaCode.length > 0) {
    authBody.append('mfaCode', state.mfaCode);
  }
  return authBody;
};

function useExpiredLogic() {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector(s => s.login);


  React.useEffect(() => {
    if (state.uiStage !== UI_STAGE_EXPIRATION &&
        state.expired === true &&
        state.reset === false &&
        state.mfa === false) {
      handleLoginUIStage(reduxDispatch, UI_STAGE_EXPIRATION);
    }
  }, [state.uiStage, state.reset, reduxDispatch]);

  const [intlUnknowError,
    intlError,
    intlWarning,
  ] = React.useMemo(
    () => R.map(id => intl.formatMessage({ id }),
      [
        'UNKNOWN_ERROR',
        'ERROR',
        'WARNING',
      ]), [intl]);

  const handleResult = React.useCallback(handleLoginHandleResult(reduxDispatch), [reduxDispatch]);
  const handleClose = React.useCallback(handleLoginCleanResult(reduxDispatch), [reduxDispatch]);
  const handleOpenReset = React.useCallback(() => handleLoginUpdateField(reduxDispatch, 'reset', true), [reduxDispatch]);
  const handleShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch, UI_STAGE_EXPIRATION), [reduxDispatch]);
  const handleLoading = React.useCallback(handleLoginPageLoading(reduxDispatch,UI_STAGE_EXPIRATION),[reduxDispatch]);

  const handleAuthError = React.useCallback(({ json, message, error }) => {
    handleLoading(false);
    if (error) {
      handleShowAlert('error', {
        title: intlError,
        msg: intlUnknowError
      });
    } else if (json) {
      handleResult(json);
      if (message) {
        handleShowAlert('error', {
          title: intlError,
          msg: intl.formatMessage({ id: message, defaultMessage: message }),
        });
      }
    }
  }, [handleResult, handleShowAlert,
      intlError, intlUnknowError, intl,
      handleLoading]);

  const authWithServer = useAuthLogic(handleAuthError);
  const handleCancel = React.useCallback(() => {
    if (state.expired === true && state.result.daysBeforePasswordExpiration <= 0){
      handleClose();
      navigate('/login');
    }else{
      const body = buildAuthBody(state);
      handleLoading(true);
      authWithServer(body);
    }

  },[state,navigate,
     authWithServer,handleClose,
    handleLoading]);


  return {
    state,
    handleCancel,
    handleOpenReset,
  };
}

export default useExpiredLogic;
