import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import {useIntl} from 'react-intl';
import { handleLoginCleanAlert, handleLoginShowError, handleLoginShowAlert } from 'redux/loginAction';

const theIntlKey = (path,dict) => R.ifElse(
  R.is(Function),
  (f) => R.apply(f,[path,dict]),
  R.identity
);

function useLoginErrorLogic(path,codeDict) {
  const reduxDispatch = useDispatch();
  const intl = useIntl();
  const intlUnknowError = React.useMemo(
    () => intl.formatMessage({ id: 'UNKNOWN_ERROR', defaultMessage: 'UNKNOWN_ERROR' }),
    [intl]);
  const codeToIntl = React.useCallback(R.ifElse(
    R.has(R.__, codeDict),
    R.prop(R.__, codeDict),
    R.identity),[codeDict]);
  const handleShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch, path), [reduxDispatch, path]);
  const handleCleanError = React.useCallback(() => {
    handleLoginCleanAlert(reduxDispatch,path);
  },[reduxDispatch,path]);
  const handleShowError = React.useCallback(
    ({message,messageDict,title,severity}) => {
    if(R.is(String,message) || R.is(Number,message)){
      const intlKey = R.pipe(
        codeToIntl,
        theIntlKey(path,messageDict))(message);
      const msg = R.ifElse(
        R.isNil,
        R.always(intl.formatMessage({ id: intlKey, defaultMessage: intlKey })),
        (dict) => intl.formatMessage({ id: intlKey, defaultMessage: intlKey }, dict)
      )(messageDict);
      const msgObject = R.ifElse(
        R.isNil,
        R.always({msg}),
        (t) => ({title: t,msg})
      )(title);
      const theSeverity = R.ifElse(
        R.isNil,
        R.always('error'),
        R.identity
      )(severity);
      handleLoginShowAlert(reduxDispatch,path,theSeverity,msgObject);
    }else {
      const msgObject = R.ifElse(
        R.isNil,
        R.always({msg: intlUnknowError}),
        (t) => ({title: t, msg: intlUnknowError})
      )(title);
      const theSeverity = R.ifElse(
        R.isNil,
        R.always('error'),
        R.identity
      )(severity);
      handleLoginShowAlert(reduxDispatch,path,theSeverity, msgObject);
    }
  },[reduxDispatch,path,intl,intlUnknowError,codeToIntl]);

  return {
    handleCleanError,
    handleShowError,
    handleShowAlert,
  };

}

export default useLoginErrorLogic;
