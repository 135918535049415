import React from 'react';
import * as R from 'ramda';
import {
  doAuth
} from 'common/api';

import { authErrorCodeToIntl } from 'common/intl';


const auth = R.curry((handleError, body) => {
  const locale = localStorage.getItem('locale');
  const loginURL = sessionStorage.getItem('loginURL');
  const back = sessionStorage.getItem('back');
  body.append('locale', locale);
  body.append('loginURL',loginURL);
  if (back !== null && back !== undefined) {
    body.append('back', back);
  }
  return doAuth(body)
    .then(response => response.json()
          .then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (response.ok && json.redirectURL !== undefined) {
        const redirectUrl = json.redirectURL;
        window.location.href = redirectUrl;
      } else {
        if(json.message) {
          const message = authErrorCodeToIntl(json.message);
          handleError({json,response,message});
        } else if (json.msg) {
          const message = authErrorCodeToIntl(json.msg);
          handleError({json,response,message});
        } else {
          handleError({json,response});
        }
      }
    }).catch((error) => {
      handleError({error});
    })});

function useAuthLogic(handleError){
  return React.useCallback(auth(handleError),[handleError]);
}

export default useAuthLogic;
