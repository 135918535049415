import React from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function NooshAlert(props){
  const {
    title,
    message,
    severity,
    sx ={}
  }=props;

  if(title !== undefined && title.length >0) {
    return (<Alert severity={severity} sx={sx}>
              <AlertTitle>{title}</AlertTitle>
              {message}
            </Alert>);
  }

  return (
    <Alert severity={severity} sx={sx}>
      {message}
    </Alert>);
}

export default  NooshAlert;
