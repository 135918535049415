import React from 'react';
import * as R from 'ramda';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import useResetLogic from 'components/login/resetLogic';
import { useIntl } from 'react-intl';

import LanguageSelectControl from 'components/intl/LanguageSelectControl';
import { UI_STAGE_RESET } from './loginUIStage';



function ResetForm(){
  const intl = useIntl();
  const [
    intlCurrentPassword,
    intlNewPassword,
    intlSubmit,
    intlCancel,
    intlConfirmNewPassword,
    intlPasswordNotMatched
  ] = React.useMemo(() =>R.map(
    i => intl.formatMessage({id: i, defaultMessage: i}),
    ['CURRENT_PASSWORD',
     'NEW_PASSWORD',
     'SUBMIT',
     'CANCEL',
     'CONFIRM_NEW_PASSWORD',
     'PASSWORD_NOT_MATCHED'
    ]),[intl]);
  const {
    state,
    handleUpdateCurrentPassword,
    handleUpdateNewPassword,
    handleUpdateConfirmPassword,
    handleCurrentPasswordKeyDown,
    handleNewPasswordKeyDown,
    handleConfirmPasswordKeyDown,
    handleAuth,
    handleCancel,
  } = useResetLogic();


  const hasConfirmPassword = React.useMemo(
    () => state.confirmPassword !== undefined &&
      state.confirmPassword !== null &&
      state.confirmPassword.length > 0,
    [state]);
/*
  const hasPassword = React.useMemo(
    () => state.password !== undefined &&
      state.password !== null &&
      state.password.length > 0 &&
      state.currentPassword !== undefined &&
      state.currentPassword !== null &&
      state.currentPassword.length > 0,
    [state]);

*/
  const loading = React.useMemo(() => {
    const stageLoading = R.view(R.lensPath(['loading', UI_STAGE_RESET]), state);
    if (stageLoading === undefined || stageLoading === null) {
      return false;
    }
    return stageLoading;
  }, [state]);
  if (state.reset === false || 
    state.mfa === true  ||
    (state.reset === true && state.resetMessage === true)) return null;

  return (
    <FormGroup>
      <FormControl
        sx={{ mb: 3 }}
        variant="outlined">
        <InputLabel htmlFor="outlined-current-password">{intlCurrentPassword}</InputLabel>
        <OutlinedInput
          autoComplete="false"
          autoFocus={true}
          onBlur={handleCurrentPasswordKeyDown(true)}
          onKeyDown={handleCurrentPasswordKeyDown(false)}
          id="outlined-current-password"
          type="password"
          value={state.currentPassword}
          onChange={(event) => handleUpdateCurrentPassword(event.target.value)}
          label={intlCurrentPassword}
        />
      </FormControl>
      <FormControl
        sx={{ mb: 3 }}
        variant="outlined">
        <InputLabel htmlFor="outlined-new-password">{intlNewPassword}</InputLabel>
        <OutlinedInput
          autoComplete="false"
          onBlur={handleNewPasswordKeyDown(true)}
          onKeyDown={handleNewPasswordKeyDown(false)}
          id="outlined-new-password"
          type="password"
          value={state.newPassword}
          onChange={(event) => handleUpdateNewPassword(event.target.value)}
          label={intlNewPassword}
        />
      </FormControl>
      <FormControl
        sx={{ mb: 3 }}
        variant="outlined">
        <InputLabel htmlFor="outlined-confirm-password">{intlConfirmNewPassword}</InputLabel>
        <OutlinedInput
          autoComplete="false"
          error={!state.newPasswordMatched && state.confirmPasswordVerified}
          onBlur={handleConfirmPasswordKeyDown(true)}
          onKeyDown={handleConfirmPasswordKeyDown(false)}
          id="outlined-confirm-password"
          type="password"
          value={state.confirmPassword}
          onChange={(event) => handleUpdateConfirmPassword(event.target.value)}
          label={intlConfirmNewPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
              >
                {hasConfirmPassword && state.confirmPasswordMatched ? <CheckCircleOutlineOutlinedIcon sx={{color: 'green'}} /> : null }
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText sx={{
          ml: 0,
          mr: 0,
          color: '#F44336'
        }}>{ hasConfirmPassword && !state.confirmPasswordMatched  ? intlPasswordNotMatched: ''}</FormHelperText>
      </FormControl>
      <LanguageSelectControl sx={{mb: 4}}/>
    <FormControl sx={{mb:3}}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="contained"
            onClick={handleAuth}
          >
            {intlSubmit}
          </LoadingButton>
        </FormControl>
      <FormControl>
        <Button
          variant="outlined"
          onClick={handleCancel}
        >
          {intlCancel}
        </Button>
      </FormControl>
    </FormGroup>
  );
}

export default ResetForm;
