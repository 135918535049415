import React from 'react';
import {createTheme, ThemeProvider,} from '@mui/material/styles';
import {Provider} from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { createAppStore } from 'redux/store';
import Intl from 'components/intl/Intl';
import Layout from 'page/Layout';
import NoMatch from 'page/NoMatch';
import LoginPage from 'page/noosh/LoginPage';
import ForgetUserIdPage from 'page/noosh/ForgetUserIdPage';
import ForgetPasswordPage from 'page/noosh/ForgetPasswordPage';
import GoogleAnalytics from "./GoogleAnalytics";

const store = createAppStore();

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none"
    }
  }
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <GoogleAnalytics/>
        <Intl>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<LoginPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="forget-user-id" element={<ForgetUserIdPage />} />
                <Route path="forget-password" element={<ForgetPasswordPage />} />
                <Route path=":portal/login" element={<LoginPage />} />
                <Route path=":portal/forget-user-id" element={<ForgetUserIdPage />} />
                <Route path=":portal/forget-password" element={<ForgetPasswordPage />} />
                <Route path="*" element={<NoMatch />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Intl>
      </Provider>
    </ThemeProvider>);
}

export default App;
