import React from 'react';
import * as R from 'ramda';

import { useDispatch, useSelector } from 'react-redux';

import {
  handleLoginUpdateField,
  handleLoginShowPassword,
  handleLoginHandleResult,
  handleLoginShowAlert,
  handleLoginCleanAlert,
  handleLoginPageLoading,
  handleLoginDrawerLoading,
} from 'redux/loginAction';


import useAuthLogic from './authLogic';
import usePreAuthLogic from './preAuthLogic';
import { useIntl } from 'react-intl';
import { UI_STAGE_LOGIN } from './loginUIStage';

function useLoginLogic() {
  const reduxDispatch = useDispatch();
  const loginState = useSelector(state => state.login);
  const intl = useIntl();
  const [intlUnknowError,
         intlError,
         intlWarning,
         intlNoRemeberMe,
        ] = React.useMemo(
          () => R.map(id => intl.formatMessage({id}),
            [
              'UNKNOWN_ERROR',
              'ERROR',
              'WARNING',
              'NO_REMEBER_ME'
            ]),[intl]);

  const [
    handleUpdateAccount,
    handleUpdatePassword,
    handleUpdateRemember,
  ] = React.useMemo(
    () => R.map(
      f => handleLoginUpdateField(reduxDispatch,f),
      ['account','password','remember']),
    [reduxDispatch]);

  const handleResult = React.useCallback(handleLoginHandleResult(reduxDispatch), [reduxDispatch]);
  const handleShowPassword = React.useCallback(handleLoginShowPassword(reduxDispatch),[reduxDispatch]);
  const handleLoading = React.useCallback(handleLoginPageLoading(reduxDispatch,UI_STAGE_LOGIN),[reduxDispatch]);
  const handleShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch, UI_STAGE_LOGIN), [reduxDispatch]);
  const handleAccountBlur = React.useCallback(() =>{
    if(R.last(loginState.account) === ' '){
      const result = R.reduceRight((s,[flag,acc]) => {
        if(flag === false && s === ' '){
          return [flag,acc];
        }
        return [true,s + acc];
      },[false,''],loginState.account);
      handleLoginUpdateField(reduxDispatch,'account',result[1]);
    }
  },[reduxDispatch,loginState.account]);
  const handleAuthError = React.useCallback(({json,message,error})=>{
    handleLoading(false);
    if(error) {
      handleShowAlert('error', {
        title: intlError,
        msg: intlUnknowError
      });
    } else if(json) {
      handleResult(json);
      if(message) {
        handleShowAlert('error', {
          title: intlError,
          msg: intl.formatMessage({ id: message, defaultMessage: message }),
        });
      }
    }
  },[handleResult,handleShowAlert,
     intlError,intlUnknowError,intl,handleLoading]);

  const authWithServer = useAuthLogic(handleAuthError);

  const handlePreAuthSuccess = React.useCallback(({body,json}) => {
    if (json.mfa === false &&
        json.resetPassword === false &&
        json.daysBeforePasswordExpiration === undefined &&
        json.showNotSupportRememberMe === false ) {
      //    handleLoginCleanAlert(reduxDispatch, UI_STAGE_LOGIN);
      authWithServer(body);
    }else {
      handleLoginCleanAlert(reduxDispatch, UI_STAGE_LOGIN);
      handleLoading(false);
      handleResult(json);
      if(json.showNotSupportRememberMe) {
        handleShowAlert('warning',{
          title: intlWarning,
          msg: intlNoRemeberMe
        });
      }
    }
  },[handleResult,authWithServer,
     intlError,intlWarning,intlNoRemeberMe,
     handleShowAlert,handleLoading,reduxDispatch]);

  const handlePreAuthError = React.useCallback(({error,message})=> {
    handleLoading(false);
    if (error) {
      handleShowAlert('error',{
        title: intlError,
        msg: intlUnknowError
      });
    } else if (message) {
      handleShowAlert('error',{
        title: intlError,
        msg: intl.formatMessage({id: message,defaultMessage: message}),
      });
    }
  },[handleShowAlert,intlUnknowError,intlUnknowError,intl,handleLoading]);

  const preAuthWithServer = usePreAuthLogic(handlePreAuthSuccess,handlePreAuthError);
  const handleAuth = React.useCallback(()=>{
    const body = new URLSearchParams();
    body.append('j_username',loginState.account);
    body.append('j_password', loginState.password);
    body.append('remember-me',loginState.remember);
    handleLoading(true);
    preAuthWithServer(body);

  },[preAuthWithServer,reduxDispatch,handleLoading,loginState]);

  React.useEffect(() => {
    if (loginState.delayLogin) {
      handleLoading(true);
      handleLoginUpdateField(reduxDispatch,'delayLogin',false);
      const body = new URLSearchParams();
      body.append('j_username', loginState.account);
      body.append('j_password', loginState.password);
      setTimeout(() => {
        authWithServer(body);
      },3000);
    }
  }, [loginState.delayLogin,
      reduxDispatch,
      authWithServer,
      handleLoading,
      handleLoginUpdateField]);
    React.useEffect(()=>{
      const mode = sessionStorage.getItem('mode');
      const failReason = sessionStorage.getItem('failReason');
      if(mode !== null && mode !== undefined){
        if (mode === 'default') {
          handleShowAlert('error', {
            title: intlError,
            msg: decodeURIComponent(failReason)
          });
        } else if(mode !== 'pep_sso_login_failed') {
          handleShowAlert('error',{
            title: intlError,
            msg: intl.formatMessage({id: mode,defaultMessage: mode}),
          });
        }else {
          handleShowAlert('error',{
            title: intlError,
            msg: decodeURIComponent(failReason)
          });
        }
      }
    },[handleShowAlert,intl,intlError]);
  return {
    loginState,
    handleUpdateAccount,
    handleUpdatePassword,
    handleUpdateRemember,
    handleShowPassword,
    handleAuth,
    handleAccountBlur
  };
}

export default useLoginLogic;
