import * as R from 'ramda';
import {genReduxReducer} from 'common/tool';

const FORGET_USER_ID_UPDATE_FIELD = 'FORGET_USER_ID_UPDATE_FIELD';
const FORGET_USER_ID_SUCCESS = 'FORGET_USER_ID_SUCCESS';
const FORGET_USER_ID_RESET = 'FORGET_USER_ID_RESET';
const FORGET_USER_ERROR = 'FORGET_USER_ERROR';

const forgetUserIdActionTypes = [
  FORGET_USER_ID_UPDATE_FIELD,
  FORGET_USER_ID_SUCCESS,
  FORGET_USER_ID_RESET,
  FORGET_USER_ERROR
];

const forgetUserIdState = {
  email: '',
  success: false,
  message: '',
  error: null,
  loading:false
};


const forgetUserIdReducer = genReduxReducer(forgetUserIdActionTypes,forgetUserIdState);

const handleForgetUserIdUpdateField = R.curry((dispatch, field,value) => dispatch({
  type: FORGET_USER_ID_UPDATE_FIELD,
  f: R.set(R.lensProp(field),value),
}));

const handleForgetUserIdSuccess = R.curry((dispatch,message) => dispatch({
  type: FORGET_USER_ID_SUCCESS,
  f: R.mergeLeft({success: true,message: message,error: null}),
}));

const handleForgetUserIdReset = (dispatch)=> dispatch({
  type: FORGET_USER_ID_RESET,
  f: R.always(forgetUserIdState)
});

const handleForgetUserIdError = R.curry((dispatch,error)=> dispatch({
  type: FORGET_USER_ERROR,
  f: R.over(R.lensProp('error'),
    R.always({
      open: true,
      severity: 'error',
      ...error
    }))
}));

const handleForgetUserIdLoading = R.curry((dispatch,loading) => dispatch({
  type: FORGET_USER_ID_UPDATE_FIELD,
  f: R.over(R.lensProp('loading'),R.always(loading))
}));

export {
  forgetUserIdReducer,
  handleForgetUserIdUpdateField,
  handleForgetUserIdSuccess,
  handleForgetUserIdReset,
  handleForgetUserIdError,
  handleForgetUserIdLoading
};
