import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import TagManager from "@sooro-io/react-gtm-module";
import boostrapLogin from './bootstrap';

window.noosh = window.noosh || {}


const runtime = () => {
  const timestamp = new Date().getTime();
  const runtimePromise = new Promise((resolve, reject) => {
    fetch(`/rt.json?t=${timestamp}`)
      .then((response) => response.json().then(json => json))
      .then((res) => {
        window.noosh = res
        resolve();
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return runtimePromise;
};

runtime().then(
  (res) => {
    //import reportWebVitals from './reportWebVitals';
    const GA_MEASUREMENT_ID = window.noosh.ga_measurement_id;
    if (!(window.noosh.ga_enabled === false ||
      window.noosh.ga_enabled === undefined ||
      GA_MEASUREMENT_ID === undefined)) {
      TagManager.initialize({
        gtmId: GA_MEASUREMENT_ID
      });
    }
  }).catch((e) => {
    console.log("we can't load rt.json");
  }).finally(() => {
    boostrapLogin();
  })


