import React from 'react';
import * as R from 'ramda';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import useLoginLogic from './loginLogic';
import { useIntl } from 'react-intl';
import LanguageSelectControl from 'components/intl/LanguageSelectControl';
import LoadingButton from '@mui/lab/LoadingButton';
import { UI_STAGE_LOGIN } from './loginUIStage';

function LoginForm(){
  const intl = useIntl();
  const {
    loginState,
    handleUpdateAccount,
    handleUpdatePassword,
    handleUpdateRemember,
    handleShowPassword,
    handleAuth,
    handleAccountBlur,
  } = useLoginLogic();


  const [
    intlWarning,
    intlNoRemeberMe,
    intlUserID,
    intlRememberMe,
    intlLogin,
    intlPassword
  ] = React.useMemo(() => R.map(
    id => intl.formatMessage({id,defaultMessage: id}),
    ['WARNING',
     'NO_REMEBER_ME',
     'USER_ID',
     'REMEMBER_ME',
     'LOGIN',
     'PASSWORD'
    ]),[intl]);

    
  const handleMouseDownPassword = React.useCallback((event) => {
    event.preventDefault();
  },[]);

  const handleKeyDown = React.useCallback((event) => {
    if (event.key === 'Enter') {
      handleAuth(loginState);
    }
  },[handleAuth,loginState]);

  const loading = React.useMemo(() => {
    const stageLoading = R.view(R.lensPath(['loading',UI_STAGE_LOGIN]),loginState);
    if(stageLoading === undefined || stageLoading === null){
      return false;
    }
    return stageLoading;
  },[loginState]);
  if (loginState.mfa === false && (loginState.expired === true || loginState.reset === true)) return null;
  return (
      <FormGroup
      sx={{width: '100%'}}>
      <FormControl sx={{mb:4}} variant="outlined">
          <TextField
           autoFocus={true} 
            id="outlined-userid"
            autoComplete="on"
            value={loginState.account}
            onBlur={handleAccountBlur}
            onChange={(event) => {
              if (loading === true || loginState.mfa === true) return;
              handleUpdateAccount(event.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            label={intlUserID}
            name="j_username"
            
          />
        </FormControl>
      <FormControl sx={{ mb: 4 }} variant="outlined">
          <TextField
            id="outlined-adornment-password"
            autoComplete="on"
            type={loginState.showPassword ? 'text' : 'password'}
            value={loginState.password}
            onChange={(event) => {
              if (loading === true || loginState.mfa === true) return;
              handleUpdatePassword(event.target.value);
            }}
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
            endAdornment:<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleShowPassword(!loginState.showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {loginState.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
          }}
            name="j_password"
            label={intlPassword}
          />
        </FormControl>
        <LanguageSelectControl sx={{mb: 4}}/>
        <FormControlLabel
          sx={{ mb: 4 }}
          control={
            <Checkbox
              checked={loginState.remember}
              value={loginState.remember}
              onChange={(event) =>handleUpdateRemember(event.target.checked)}
              name="remember-me" />}
          label={intlRememberMe}
        />
        <FormControl>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="contained"
            onClick={handleAuth}
          >
            {intlLogin}
          </LoadingButton>
        </FormControl>
    </FormGroup>);
}

export default LoginForm;
