import React from 'react';
import * as R from 'ramda';

import { useIntl } from 'react-intl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import useLanguage from 'components/intl/languageLogic';

function LanguageSelectControl(props){
  const {
    sx ={}
  } = props;
  const intl = useIntl();
  const {
    language,
    languages,
    handleSwitchLanguage
  } = useLanguage();

  const renderLanguages = React.useCallback(
    () => R.map(
      (item) => (<MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>),
      languages),
    [languages]);

  const intlLanguage = React.useMemo(() => intl.formatMessage({id: 'LANGUAGE'}),[intl]);

  return (
    <FormControl sx={sx} variant="outlined">
      <InputLabel id="select-language">{intlLanguage}</InputLabel>
      <Select
        value={language}
        onChange={(event) => handleSwitchLanguage(event.target.value)}
        labelId="select-language"
        id="select-lanugae"
        label={intlLanguage}
      >
        {renderLanguages()}
      </Select>
    </FormControl>);
}

export default LanguageSelectControl;
