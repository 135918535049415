import React from 'react';
import * as R from 'ramda';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import LanguageSelectControl from 'components/intl/LanguageSelectControl';
import useForgetPasswordLogic from './forgetPasswordLogic';
import { useIntl } from 'react-intl';

function ForgetPasswordForm(){

  const {
    state,
    handleUpdateUserId,
    handleResetPassword,
    handleResetState
  } = useForgetPasswordLogic();
  const intl = useIntl();
  const [
    intlUserID,
    intlSubmit,
    intlCancel
  ] = React.useMemo(
    () => R.map(
      id => intl.formatMessage({id}),
      ['USER_ID',
       'SUBMIT',
       'CANCEL'
      ]),[intl]);

  return (
    <FormGroup>
      <FormControl sx={{ mb: 4 }} variant="outlined">
        <InputLabel htmlFor="outlined-userid">{intlUserID}</InputLabel>
        <OutlinedInput
          id="outlined-userid"
          autoFocus={true}
          label={intlUserID}
          value={state.userId}
          onChange={(e) => handleUpdateUserId(e.target.value)}
        />
      </FormControl>
      <LanguageSelectControl sx={{mb: 4}}/>
      <FormControl sx={{ mb: 3 }}>
        <LoadingButton
          loading={state.loading}
          loadingPosition="start"
          variant="contained"
          onClick={handleResetPassword}
        >
          {intlSubmit}
        </LoadingButton>
      </FormControl>
      <FormControl>
        <Button
          disabled={state.loading}
          variant="outlined"
          onClick={handleResetState}
        >
          {intlCancel}
        </Button>
      </FormControl>
    </FormGroup>);

}

export default ForgetPasswordForm;
