import React from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import StandardLayout from 'components/layout/StandardLayout';
import ForgetPasswordTitle from 'components/forget/ForgetPasswordTitle';
import ForgetPasswordForm from 'components/forget/ForgetPasswordForm';
import ForgetCommitted from 'components/forget/ForgetCommitted';
import ForgetLink from 'components/layout/ForgetLink';
import { handleForgetPasswordReset } from 'redux/forgetPasswordAction';

import ForgetBox from 'components/forget/ForgetBox';
import TagManager from "@sooro-io/react-gtm-module";

function ForgetPasswordPage() {
  const REACT_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const GA_MEASUREMENT_ID = window.noosh.ga_measurement_id || REACT_GA_MEASUREMENT_ID;
  React.useLayoutEffect(() => {
    const openArgs = {
      dataLayer: {
        event: 'login_forget_password_page',
        eventName: 'login_forget_password_page',
      },
    };
    if(GA_MEASUREMENT_ID) {
      TagManager.dataLayer(openArgs);
    }
  },[GA_MEASUREMENT_ID]);
  const reduxDispatch = useDispatch();
  const state = useSelector(s => s.forgetPassword);
  if(state.success){
    return (<ForgetBox store="forgetPassword">
              <ForgetCommitted
                onClick={() => handleForgetPasswordReset(reduxDispatch)}
                message={state.message}
              />
            </ForgetBox>);
  }
  return (<ForgetBox store="forgetPassword">
            <ForgetPasswordTitle />
            <ForgetPasswordForm />
            <ForgetLink />
          </ForgetBox>);
}

export default ForgetPasswordPage;
