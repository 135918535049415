import React from 'react';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

function Copyright(){
  const intl = useIntl();
  return (
    <Box sx={{
      position: 'relative',
    //  marginTop: '-24px',
      height: '24px',
      width: '100%',
      clear: 'both',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.4)',
      fontSize: '14px',
      }}>
      &copy;{intl.formatMessage({id: 'COPYRIGHT'})}
    </Box>
  );
}

export default Copyright;
