import * as R from 'ramda';
import {genReduxReducer} from 'common/tool';

const FORGET_PASSWORD_UPDATE_FIELD = 'FORGET_PASSWORD_UPDATE_FIELD';
const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
const FORGET_PASSWORD_RESET = 'FORGET_PASSWORD_RESET';
const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

const forgetPasswordActionTypes = [
  FORGET_PASSWORD_UPDATE_FIELD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_RESET,
  FORGET_PASSWORD_ERROR,
];

const forgetPasswordState = {
  userId: '',
  success: false,
  message: '',
  error: null,
  loading: false,
};

const forgetPasswordReducer = genReduxReducer(forgetPasswordActionTypes,forgetPasswordState);

const handleForgetPasswordUpdateField = R.curry((dispatch, field,value) => dispatch({
  type: FORGET_PASSWORD_UPDATE_FIELD,
  f: R.set(R.lensProp(field),value),
}));

const handleForgetPasswordSuccess = R.curry((dispatch,message) => dispatch({
  type: FORGET_PASSWORD_SUCCESS,
  f: R.mergeLeft({success: true,message: message,error: null}),
}));

const handleForgetPasswordReset = (dispatch)=> dispatch({
  type: FORGET_PASSWORD_RESET,
  f: R.always(forgetPasswordState)
});

const handleForgetPasswordError = R.curry((dispatch,error)=> dispatch({
  type: FORGET_PASSWORD_ERROR,
  f: R.over(R.lensProp('error'),
            R.always({
              open: true,
              severity: 'error',
              ...error}))
}));

const handleForgetPasswordLoading = R.curry((dispatch,loading)=> dispatch({
  type: FORGET_PASSWORD_UPDATE_FIELD,
  f: R.over(R.lensProp('loading'),R.always(loading))
}));

export {
  forgetPasswordReducer,
  handleForgetPasswordUpdateField,
  handleForgetPasswordSuccess,
  handleForgetPasswordReset,
  handleForgetPasswordError,
  handleForgetPasswordLoading,
};
