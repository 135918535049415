import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleForgetPasswordUpdateField,
  handleForgetPasswordSuccess,
  handleForgetPasswordError,
  handleForgetPasswordLoading,
  handleForgetPasswordReset,
} from 'redux/forgetPasswordAction';


import {
  doResetPassword
} from 'common/api';

import {
  genErrorCodeToIntl,
} from 'common/intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';


const errorCode = {
  404: 'NONE_EXSIST_USER',
  10001: 'PASSWORD_RESET_NOT_ALLOWED'
};

const errorCodeToIntl = genErrorCodeToIntl(errorCode);


function useForgetPasswordLogic() {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.forgetPassword);
  const [
    intlUnknowError,
    intlError,
  ] = React.useMemo(
    () => R.map(
      id => intl.formatMessage({ id }),
      [
        'UNKNOWN_ERROR',
        'ERROR',
      ]), [intl]);

  const handleShowError = React.useCallback(handleForgetPasswordError(reduxDispatch),[reduxDispatch]);
  const handleResetSuccess = React.useCallback(handleForgetPasswordSuccess(reduxDispatch),[reduxDispatch]);
  const handleUpdateUserId = React.useCallback(handleForgetPasswordUpdateField(reduxDispatch,'userId'),[reduxDispatch]);
  const handleLoading = React.useCallback(handleForgetPasswordLoading(reduxDispatch),[reduxDispatch]);

  const handleResetState = React.useCallback(()=> {
    handleForgetPasswordReset(reduxDispatch);
    navigate('/login');
  },[reduxDispatch,navigate]);

  const handleResetPassword = React.useCallback(() => {
    const username = state.userId;
    handleLoading(true);
    doResetPassword(username)
      .then((response) => {
        handleLoading(false);
        if(response.ok){
          return response.text().then(text => ({text, response}));
        }else{
          return response.json().then(json => ({ json,response }));
        }
      }).then(({ text, json}) => {
        if (text) {
          handleResetSuccess(text);
        } else {

          if (json.message) {
            const intlMsg = errorCodeToIntl(json.message);
            let msg = null;
            if (json.messageDict) {
              msg = intl.formatMessage({ id: intlMsg }, json.messageDict);
            } else {
              msg = intl.formatMessage({ id: intlMsg });
            }
            handleShowError({
              title: intlError,
              msg: msg
            });
          } else if  (json.msg) {
            const intlMsg = errorCodeToIntl(json.msg);
            let msg = null;
            if (json.msgParams) {
              msg = intl.formatMessage({ id: intlMsg }, json.msgParams);
            } else {
              msg = intl.formatMessage({ id: intlMsg });
            }
            handleShowError({
              title: intlError,
              msg: msg
            });
          } else {
            handleShowError({
              title: intlError,
              msg: intlUnknowError
            });
          }
        }
      }).catch((error) => {
        handleLoading(false);
        handleShowError({ title: intlError, msg: intlUnknowError });
      });
  },[handleResetSuccess,
     handleShowError,
     intlError,
     intlUnknowError,
     state,intl,handleLoading]);
  return {
    state,
    handleUpdateUserId,
    handleResetPassword,
    handleResetState
  };
}

export default useForgetPasswordLogic;
