import React from 'react';
import {useIntl} from 'react-intl';
import * as R from 'ramda';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import useMFALogic from './mfaLogic';
import NooshAlert from 'components/alert/NooshAlert';
import LoadingButton from '@mui/lab/LoadingButton';

import { useStageError, UI_DRAWER_VERIFY_CODE } from 'components/login/loginUIStage';

function VerifyCodeDrawer(){

  const intl = useIntl();
  const {
    state,
    handleClose,
    handleUpdateCode,
    handleCloseVerifyCode,
    handleSendCode,
    handleVerifyCode,
    handleUpdateRememberDevice,
    timeLeft
  } = useMFALogic();
  const error = useStageError(state.uiDrawerStage);
  const renderError = React.useCallback(() => {
    if (error === null || error === undefined) return null;
    if (error.open === false) return null;
    return (
      <NooshAlert
        title={error.title}
        message={error.msg}
        severity={error.severity}
        sx={{ mb: 1 }}
      />);

  }, [error]);
  const sendTo = React.useMemo(() => R.pipe(
    R.filter(i => i.type  === state.mfaType),
    R.head,
    R.prop('sendTo'),
  )(state.result.mfaMethods),[state]);
  const [
    intlEnterVerificationCode,
    intlDigitCode,
    intlDone,
    intlAnotherWay,
    intlRememberDevice,
    intlResendCode
  ] =  React.useMemo(
    () => R.map(
      id => intl.formatMessage({id}),
      ['ENTER_VERIFICATION_CODE',
       'DIGIT_CODE',
       'DONE',
       'ANOTER_WAY_SIGN_IN',
       'DO_NOT_ASK_ON_THIS_DEVICE',
       'RESEND_CODE'
       ]),[intl]);

  const loading = React.useMemo(() => {
    const stageLoading = R.view(R.lensPath(['loading', UI_DRAWER_VERIFY_CODE]), state);
    if (stageLoading === undefined || stageLoading === null) {
      return false;
    }
    return stageLoading;
  }, [state]);
  return (
    <Dialog open={state.verifyCode}>
      <DialogTitle sx={{m: 0,p: 2}}>
        <Grid container sx={{alignItems: 'center'}}>
    <Grid item xs={11}>
      <div>{intlEnterVerificationCode}</div>
    </Grid>
    <Grid item xs={1}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{color: (theme) => theme.palette.grey[500]}}>
        <CloseIcon />
      </IconButton>
    </Grid>
        </Grid>
      </DialogTitle>
    <DialogContent dividers>
      <Typography gutterBottom>
        {intl.formatMessage({id: 'EMAIL_SEND_TO'},{sendTo})}
      </Typography>
      {renderError()}
      <FormGroup sx={{mt: 4}}>
      <FormControl sx={{ mb: 1 }} variant="outlined">
        <OutlinedInput
          id="outlined-MFACode"
          placeholder={intlDigitCode}
          value={state.mfaCode}
          onChange={(event) => handleUpdateCode(event.target.value)}
        />
      </FormControl>
        <FormControlLabel
          sx={{ mb: 4 }}
          control={
            <Checkbox
              checked={state.rememberDevice}
              value={state.rememberDevice}
              onChange={(event) => handleUpdateRememberDevice(event.target.checked)}
              name="remember-device" />}
          label={intlRememberDevice}
        />
      <FormControl sx={{mb: 1}}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="contained"
          onClick={handleVerifyCode}
        >
          {intlDone}
        </LoadingButton>
      </FormControl>
        <FormControl sx={{ mb: 5 }}>
            <Button
              disabled={timeLeft > 0}
              variant="contained"
              onClick={() => {
                if(timeLeft <= 0){
                  handleSendCode(state.mfaType);
                }
              }}
            >
              {timeLeft <= 0 ? intlResendCode :  `${intlResendCode}(${timeLeft}s)`}
            </Button>
          </FormControl>
    </FormGroup>
    </DialogContent>
    </Dialog>
  );
}

export default VerifyCodeDrawer;
