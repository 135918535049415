/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';

function GoogleAnalytics() {
  const REACT_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const GA_MEASUREMENT_ID = window.noosh.ga_measurement_id || REACT_GA_MEASUREMENT_ID;
  const renderTitle = () => {
    return (<title>Noosh Login</title>);
  };

  const renderGA = () => {
    if(window.noosh.ga_enabled === false ||
        window.noosh.ga_enabled === undefined ||
        GA_MEASUREMENT_ID === undefined) return (<script/>);
    const url = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    return (<script async src={url}></script>);
  };
  const renderGATag = () => {
    if (window.noosh.ga_enabled === false ||
        window.noosh.ga_enabled === undefined ||
        GA_MEASUREMENT_ID === undefined) return (<script/>);
    const scriptGA = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}; gtag('js', new Date()); gtag('config','${GA_MEASUREMENT_ID}');`;
    return (<script>
      {
        scriptGA
      }
    </script>);
  };
  return (
    <Helmet>
      {renderTitle()}
      {renderGA()}
      {renderGATag()}
    </Helmet>
  );
}

export default GoogleAnalytics;
