import * as R from 'ramda';

const codeDict = {
  2006: 'INVALID_LOGIN_OR_PASSWORD',
  9931: 'LOGIN_ACCESS_PASSWORD_DISABLED',
  9934: 'LOGIN_ACCESS_PASSWORD_RESET_OUTDATED',
  6303: 'INVALID_LOGIN_ACCOUNT_LOCKED',
  7580: 'INVALID_LOGIN_WORKGROUP_LOCKED',
  8519: 'USER_NOT_ACTIVATED',
  6304: 'INVALID_PASSWORD_LOCKOUT_WARNING',
  6305: 'INVALID_PASSWORD_LOCKOUT',
  9933: 'LOGIN_ACCESS_PASSWORD_DISABLED_FORGET_USERID',
  9932: 'LOGIN_ACCESS_PASSWORD_DISABLED_RESET',
  301: 'PASSWORD_IN_HISTORY',
  302: 'PASSWORD_CURRENT_DOES_NOT_MATCH',
  303: 'PASSWORD_CANNOT_MATCH',
  5: 'PASSWORD_CANNOT_MATCH',
};

const authErrorCodeToIntl = R.ifElse(
  R.has(R.__, codeDict),
  R.prop(R.__, codeDict),
  R.identity);


const genErrorCodeToIntl = errorCodeDict => R.ifElse(
  R.has(R.__,errorCodeDict),
  R.prop(R.__,errorCodeDict),
  R.identity);

export {
  authErrorCodeToIntl,
  genErrorCodeToIntl
};
