import * as R from 'ramda';

const notExist = R.either(R.isNil,R.isEmpty);

const doIt = state => R.ifElse(
  notExist,
  R.always(state),
  R.applyTo(state),
);


const actionReducer = (state, action) => doIt(state)(action);
const genReduxReducer = (types, initialState) => (state = initialState, action) => {
  if (R.includes(R.prop('type', action), types)) {
    return doIt(state)(R.prop('f', action));
  }
  return state;
};

const getBaseHost = R.memoizeWith(R.identity, R.pipe(
  R.split('.'),
  R.drop(1),
  R.join('.')));

const getCookie = R.curry((key,cookies) =>  R.pipe(
  R.split('; '),
  R.find(R.startsWith(key + '=')),
  R.split('='),
  R.last)(cookies));


const isEnv = (env) => {
  const hosts = R.split('.',window.location.hostname);
  const nooshIndex = R.findIndex(R.equals("noosh"),hosts);
  return R.equals(env,R.nth(nooshIndex - 1,hosts));
}

export {
  notExist,
  actionReducer,
  genReduxReducer,
  getBaseHost,
  getCookie,
  isEnv
};
