import React from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  handleLoginUpdateField,
  handleLoginHandleResult,
  handleLoginCleanResult,
  handleLoginUIStage,
  handleLoginPageLoading,
  handleLoginAfterReset,
} from 'redux/loginAction';


import useAuthLogic from './authLogic';
import {
  doUpdatePassword,
} from 'common/api';
import { useIntl } from 'react-intl';
import useLoginErrorLogic from './loginErrorLogic';
import {UI_STAGE_RESET} from './loginUIStage';


const codeDict = {
  302: 'PASSWORD_CURRENT_DOES_NOT_MATCH',
  5: 'PASSWORDS_DO_NOT_MATCH',
  303: 'PASSWORD_CANNOT_MATCH',
  301: (path,dict) => {
    if(R.has('passwordHistoryLength',dict)){
      const passwordHistoryLength = parseInt(dict.passwordHistoryLength);
      if(passwordHistoryLength > 1) {
        return 'PASSWORD_IS_PREVENTED_DAYS';
      }
    }
    return 'PASSWORD_IS_PREVENTED';
  },
  10007: 'PASSWORD_IS_EMPTY',
  13: 'PASSWORD_TOO_SHORT_OR_LONG',
  304: 'PASSWORD_SAME_AS_LOGIN',
  869: 'CONSTRAINT_NO_NONALPHABETIC',
  1574: 'CONSTRAINT_ILLEGAL_CHARACTER',
  9122: 'PASSWORD_CASE_ALPHANUMERIC',
  305: 'PASSWORD_STRONG_SAME_AS_NAME',
  306: 'PASSWORD_STRONG_REPEAT_CHARS',
  10001: 'PASSWORD_RESET_NOT_ALLOWED',
  2006: 'INVALID_LOGIN_OR_PASSWORD',
  10008: 'CONFIRM_NEW_PASSWORD_IS_EMPTY',
  10009: 'CONFIRM_NEW_PASSWORD_TOO_SHORT_OR_LONG',
  10010: 'CONFIRM_NEW_PASSWORD_SAME_AS_LOGIN',
  10011: 'CONFIRM_NEW_PASSWORD_NO_NONALPHABETIC',
  10012: 'CONFIRM_NEW_PASSWORD_ILLEGAL_CHARACTER',
  10013: 'CONFIRM_NEW_PASSWORD_CASE_ALPHANUMERIC',
  10014: 'CONFIRM_NEW_PASSWORD_STRONG_SAME_AS_NAME',
  10015: 'CONFIRM_NEW_PASSWORD_STRONG_REPEAT_CHARS'
};



function useResetLogic(){
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const state = useSelector(s => s.login);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state.uiStage !== UI_STAGE_RESET && state.reset === true && state.resetMessage === false) {
      handleLoginUIStage(reduxDispatch, UI_STAGE_RESET);
    }
  }, [state.uiStage, state.reset, reduxDispatch]);

  const [
    intlWarning,
    intlError,
    intlUnknownError,
    intlFieldError,
  ] = React.useMemo(
    () => R.map(
      i => intl.formatMessage({id: i,defaultMessage: i}),
      ['WARNING',
       'ERROR',
       'UNKNONW_ERROR',
       'FIELDS_ARE_BLANK_OR_HAVE_ERROR'
      ]),[intl]);

  const [
    handleUpdatePasswordMatched,
    handleUpdateNewPassword,
    handleUpdateConfirmPasswordMatched,
  ] = React.useMemo(
    () => R.map(
      f => handleLoginUpdateField(reduxDispatch, f),
      ['passwordMatched','newPassword','confirmPasswordMatched']),
    [reduxDispatch]);
  const {
    handleCleanError,
    handleShowError,
    handleShowAlert,
  } = useLoginErrorLogic(UI_STAGE_RESET,codeDict);
  const handleUpdateCurrentPassword = React.useCallback( (v) => {
/*    if(state.password !== v){
      handleUpdatePasswordMatched(false);
    }else{
      handleUpdatePasswordMatched(true);
    } */
    handleLoginUpdateField(reduxDispatch, 'currentPassword', v);
  },[reduxDispatch,state,
     handleLoginUpdateField,
     handleUpdatePasswordMatched]);

  const handleCurrentPasswordKeyDown = React.useCallback((blur) => (event) => {
/*    if (blur || (!blur && event.key === 'Enter')) {
      if (state.password !== undefined && state.password.length > 0) {
        if(state.currentPassword !== undefined && state.currentPassword.length > 0){
          handleUpdatePasswordMatched(state.password === state.currentPassword);
        }else {
          handleUpdatePasswordMatched(false);
        }
      }else{
        handleUpdatePasswordMatched(false);
      }
    }*/
  }, [state, handleUpdatePasswordMatched]);



  const handleUpdateConfirmPassword = React.useCallback((v)=> {
    if(state.newPassword !== v){
      handleUpdateConfirmPasswordMatched(false);
    }else{
      handleUpdateConfirmPasswordMatched(true);
    }
    handleLoginUpdateField(reduxDispatch, 'confirmPassword', v);
  },[state,reduxDispatch,handleLoginUpdateField,
    handleUpdateConfirmPasswordMatched]);

  const handleNewPasswordKeyDown = React.useCallback((blur) => (event) => {
    if (blur || (!blur && event.key === 'Enter')) {
      if(state.confirmPasswword !== undefined && state.confirmPassword.length > 0) {
        if (state.newPassword !== state.confirmPassword) {
          handleUpdateConfirmPasswordMatched(false);
        } else {
          handleUpdateConfirmPasswordMatched(true);
        }
      }
    }
  }, [state, handleUpdateConfirmPasswordMatched]);

  const handleConfirmPasswordKeyDown = React.useCallback((blur) => (event) => {
    if(blur || (!blur && event.key === 'Enter')) {
      if (state.newPassword !== state.confirmPassword) {
        handleUpdateConfirmPasswordMatched(false);
      } else {
        handleUpdateConfirmPasswordMatched(true);
      }
    }
  },[state,handleUpdateConfirmPasswordMatched]);

  const handleResult = React.useCallback(handleLoginHandleResult(reduxDispatch), [reduxDispatch]);
  const handleClose = React.useCallback(handleLoginCleanResult(reduxDispatch), [reduxDispatch]);
  const handleLoading = React.useCallback(handleLoginPageLoading(reduxDispatch,UI_STAGE_RESET),[reduxDispatch]);
  const handleAfterReset = React.useCallback(handleLoginAfterReset(reduxDispatch),[reduxDispatch]);

  const handleAuthError = React.useCallback(({ json, message, error }) => {
    handleLoading(false);
    if (error) {
      handleShowError({ message: error, title: intlError });
    } else if (json) {
      handleResult(json);
      if(message) {
        const messageDict = _.get(json,'messageDict');
        const msgParams = _.get(json,'msgParams',messageDict);
        handleShowError(
          {message: message,title: intlError,
           messageDict: msgParams});
      }
    }
  }, [handleShowError, handleResult, intlError,handleLoading]);

  const authWithServer = useAuthLogic(handleAuthError);

  const handleAuth = React.useCallback(() => {
/*    const hasPassword = state.password !== undefined &&
          state.password !== null &&
          state.password.length > 0 &&
          state.currentPassword !== undefined &&
          state.currentPassword !== null &&
          state.currentPassword.length > 0; */

//    if(( !hasPassword || (hasPassword && state.currentPassword === state.password))  &&
    if(state.newPassword !== undefined && state.newPassword.length > 0,
       state.newPassword === state.confirmPassword){
      const mfaCode = state.mfaCode;
      const remeberMe = state.remember;

      const updateBody = {
        username: state.account,
        currentPassword: state.currentPassword,
        newPassword: state.newPassword,
        confirmedNewPassword: state.confirmPassword
      };
      handleLoading(true);
      doUpdatePassword(JSON.stringify(updateBody))
        .then((response) => {
          if(response.ok) {
            response.json().
              then((json) => {
                const daysBeforePasswordExpiration = R.prop('daysBeforePasswordExpiration',json);
                if(daysBeforePasswordExpiration === undefined ||
                   daysBeforePasswordExpiration === null ||
                   daysBeforePasswordExpiration <= 0){
                  const body = new URLSearchParams();
                  body.append('j_username', updateBody.username);
                  body.append('j_password', updateBody.newPassword);
                  body.append('remember-me', remeberMe);
                  if (mfaCode) {
                    body.append('mfaCode', mfaCode);
                  }
                  authWithServer(body);
                } else{
                  handleLoading(false);
                  handleAfterReset(daysBeforePasswordExpiration);
                }
              });

          }else {
            response.json()
              .then((json) => {
                handleLoading(false);
                if(json.message){
                  handleShowError({title: intlError,
                                   message: json.message,
                                   messageDict: json.messageDict});
                } else if(json.msg) {
                  handleShowError({title: intlError,
                    message: json.msg,
                    messageDict: json.msgParams});
                }else {
                  handleShowError({title: intlError,message: intlUnknownError });
                }
              }).catch(error => {
                handleLoading(false);
                handleShowError({ message: intlUnknownError, title: intlError });
              });
          }
        })
        .catch((error) => {
          handleLoading(false);
          handleShowError({ message: intlUnknownError, title: intlError });
        });
    }else{
      handleShowAlert('error',{title: intlError,msg: intlFieldError});
    }
  },[state,authWithServer,
     handleShowError,handleShowAlert,
     intlError,
     intlWarning,
     intlFieldError,
     intlUnknownError,
     handleAfterReset,
     handleLoading]);

  const handleCancel = React.useCallback(() => {
    handleClose();
    navigate('/login');
  }, [state, navigate,handleClose]);


  return {
    state,
    handleUpdateCurrentPassword,
    handleUpdateNewPassword,
    handleUpdateConfirmPassword,
    handleCurrentPasswordKeyDown,
    handleNewPasswordKeyDown,
    handleConfirmPasswordKeyDown,
    handleAuth,
    handleCancel,
  };
}

export default useResetLogic;
