import * as R from 'ramda';

import {genReduxReducer} from 'common/tool';
import {
  UI_STAGE_RESET,
  UI_STAGE_LOGIN,
  UI_STAGE_EXPIRATION,
  UI_DRAWER_SEND_CODE,
  UI_DRAWER_VERIFY_CODE,
} from 'components/login/loginUIStage';

const LOGIN_UPDATE_FIELD = 'LOGIN_UPDATE_FIELD';
const LOGIN_SHOW_PASSWORD = 'LOGIN_SHOW_PASSWORD';
const LOGIN_AUTH_RESULT = 'LOGIN_AUTH_RESULT';
const LOGIN_CLEAN_RESULT = 'LOGIN_CLEAN_RESULT';
const LOGIN_MFA_SELECTED = 'LOGIN_MFA_SELECTED';
const LOGIN_CODE_SEND  = 'LOGIN_CODE_SNED';
const LOGIN_CODE_VERIFY = 'LOGIN_CODE_VERIFY';
const LOGIN_HANDLE_ERROR = 'LOGIN_HANDLE_ERROR';
const LOGIN_CLEAN_ERROR = 'LOGIN_CLEAN_ERROR';
const LOGIN_UI_STAGE = 'LOGIN_UI_STAGE';
const LOGIN_DRAWER_STAGE = 'LOGIN_DRAWER_STAGE';
const LOGIN_PAGE_LOADING = 'LOGIN_PAGE_LOADING';
const LOGIN_DRAWER_LOADING = 'LOGIN_DRAER_LOADING';
const LOGIN_AFTER_RESET = 'LOGIN_AFTER_RESET';

const loginActionTypes = [
  LOGIN_UPDATE_FIELD,
  LOGIN_SHOW_PASSWORD,
  LOGIN_AUTH_RESULT,
  LOGIN_CLEAN_RESULT,
  LOGIN_MFA_SELECTED,
  LOGIN_CODE_SEND,
  LOGIN_CODE_VERIFY,
  LOGIN_HANDLE_ERROR,
  LOGIN_CLEAN_ERROR,
  LOGIN_UI_STAGE,
  LOGIN_DRAWER_STAGE,
  LOGIN_PAGE_LOADING,
  LOGIN_DRAWER_LOADING,
  LOGIN_AFTER_RESET,
];

const loginInitialState = {
  account: '',
  password: '',
  remember: false,
  showPassword: false,
  passwordMatched: false,
  confirmPasswordMatched: false,
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  mfaCode: '',
  mfaType: '',
  rememberDevice:false,
  mfa: false,
  verifyCode: false,
  expired: false,
  reset: false,
  resetMessage: false,
  delayLogin: false,
  loading: {},
  error: {},
  uiStage: UI_STAGE_LOGIN,
  uiDrawerStage: UI_DRAWER_SEND_CODE,
  phoneNumber: '',
  result: {
    showNotSupportRemeberMe: false,
    redirectUrl: null,
    mfa: false,
    mfaMethods: [],
    resetPassword: false,
    passwordExpired: false,
    daysBeforePasswordExpiration: 0,
    mfaWorkgroupNames: []
  }
};

const buildInfo = R.ifElse(
  R.pipe(R.type, R.equals('Object')),
  R.identity,
  R.objOf('msg'));

const buildError = (severity, msg) => R.mergeLeft(buildInfo(msg), { open: true, severity });

const updateUI = (v) => R.mergeLeft({
  mfa: v.mfa === true,
  expired: v.daysBeforePasswordExpiration !== undefined,
  reset: v.resetPassword === true,
  delayLogin: (v.mfa !== true &&
               v.daysBeforePasswordExpiration === undefined &&
               v.resetPassword !== true &&
               v.showNotSupportRememberMe === true)
});


const loginReducer = genReduxReducer(loginActionTypes, loginInitialState);

const handleLoginUpdateField = R.curry((dispatch,field,value) => dispatch({
  type: LOGIN_UPDATE_FIELD,
  f: R.set(R.lensProp(field),value)
}));

const handleLoginShowPassword = R.curry((dispatch,value) => dispatch({
  type: LOGIN_SHOW_PASSWORD,
  f: R.set(R.lensProp('showPassword'),value)
}));

const handleLoginHandleResult = R.curry((dispatch,value)=> dispatch({
  type: LOGIN_AUTH_RESULT,
  f: R.pipe(
    R.over(R.lensProp('result'),R.mergeLeft(value)),
    updateUI(value)),
}));

const handleLoginCleanResult = (dispatch) => () => dispatch({
  type: LOGIN_CLEAN_RESULT,
  f: R.always(loginInitialState)
});

const handleLoginMFASelected = R.curry((dispatch,mfaType) => dispatch({
  type: LOGIN_MFA_SELECTED,
  f: R.mergeLeft({verifyCode: true,mfaType}),
}));

const handleLoginMFASend = R.curry((dispatch,mfaType) => dispatch({
  type: LOGIN_CODE_SEND,
  f: R.mergeLeft({
    verifyCode: true,
    mfaType: mfaType
  }),
}));
const handleLoginMFAVerify = (dispatch) => () => dispatch({
  type: LOGIN_CODE_SEND,
  f: R.mergeLeft({
    mfa: false,
    verifyCode: false,
    mfaType: ''
  }),
});
const handleLoginShowAlert = R.curry((dispatch,path,severity,msg) => dispatch({
  type: LOGIN_HANDLE_ERROR,
  f: R.over(R.lensPath(['error',path]),R.always(buildError(severity,msg)))
}));

const handleLoginShowError = R.curry((dispatch,path, msg) => dispatch({
  type: LOGIN_HANDLE_ERROR,
  f: R.over(R.lensPath(['error',path]), R.always(buildError('error', msg)))
}));

const handleLoginShowWarning = R.curry((dispatch,path,msg) => dispatch({
  type: LOGIN_HANDLE_ERROR,
  f: R.over(R.lensPath(['error',path]), R.always(buildError('warning', msg)))
}));

const handleLoginCleanAlert = R.curry((dispatch, path) => dispatch({
  type: LOGIN_CLEAN_ERROR,
  f: R.over(R.lensPath(['error', path]), R.always(null)),
}));

const handleLoginUIStage = R.curry((dispatch,path) => dispatch({
  type: LOGIN_UI_STAGE,
  f: R.mergeLeft({uiStage:path}),
}));

const handleLoginDrawerStage = R.curry((dispatch,path) => dispatch({
  type: LOGIN_DRAWER_STAGE,
  f: R.mergeLeft({uiDrawerStage:path}),
}));

const handleLoginPageLoading = R.curry((dispatch,path,loading)=> dispatch({
  type: LOGIN_PAGE_LOADING,
  f: R.over(R.lensPath(['loading',path]),R.always(loading))
}));

const handleLoginDrawerLoading = R.curry((dispatch, path, loading) => dispatch({
  type: LOGIN_DRAWER_LOADING,
  f: R.over(R.lensPath(['loading', path]), R.always(loading))
}));

const handleLoginAfterReset = R.curry((dispatch,daysBeforePasswordExpiration) => dispatch({
  type: LOGIN_AFTER_RESET,
  f: (s) => {
    const newPassword = s.newPassword;
    return R.pipe(
      R.mergeLeft({
        password: newPassword,
        resetMessage: true,}),
      R.over(R.lensPath(['result','daysBeforePasswordExpiration']),R.always(daysBeforePasswordExpiration))
    )(s);
  }
}));

export {
  loginReducer,
  handleLoginUpdateField,
  handleLoginShowPassword,
  handleLoginHandleResult,
  handleLoginCleanResult,
  handleLoginMFASelected,
  handleLoginMFASend,
  handleLoginMFAVerify,
  handleLoginShowAlert,
  handleLoginShowError,
  handleLoginShowWarning,
  handleLoginCleanAlert,
  handleLoginUIStage,
  handleLoginDrawerStage,
  handleLoginPageLoading,
  handleLoginDrawerLoading,
  handleLoginAfterReset
};
