import React from 'react';
import * as R from 'ramda';
import {useIntl} from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useMFALogic from 'components/login/mfaLogic';
import NooshAlert from 'components/alert/NooshAlert';
import {useStageError} from 'components/login/loginUIStage';
import { Link } from '@mui/material';
const mfaMethodStyle = (selected,disabled) => (theme) => {
  const baseStyle = {
    border: '1px solid rgba(0, 0, 0, 0.54)',
    boxSizing: 'border-box',
    borderRadius: '4px'
  };
  const selectedStyle = {
    border: '2px solid #007DFF',
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    '&  svg': {
      color: '#007DFF'
    }
  };
  const disabledStyle = {
    border: '2px solid gray',
    '&  svg': {
      color: 'gray'
    }
  }
  if(disabled) {
    return R.mergeLeft(disabledStyle, baseStyle);
  }
  if(selected){
    return R.mergeLeft(selectedStyle,baseStyle);

  }else {
    return  R.mergeLeft({
      '&:hover': { ...selectedStyle}
    },baseStyle);
  }
};



function MFADrawer(){
  const intl = useIntl();
  const [
    intlEmail,
    intlSMS,
    intlSecurityMethod,
    intlChooseSecurityMethod,
    intlEnableMFAGroups,
    intlWorkgroup,
    intlEnableMFA,
    intlEnableMFATrue,
    intlBack,
    intlCheckMore,
    intlChangeNumber,
    intlInputPhoneNumber,
    intlUseDifferentPhoneNumber,
    intlDone,
    intlPhoneNumberPlaceholder,
    intlUseNewPhoneNumber
  ] = React.useMemo(
    () =>  R.map(
      id => intl.formatMessage({id}),
      ['EMAIL',
       'SMS',
       'SECURITY_METHOD',
       'CHOOSE_SECURITY_METHOD',
       'ENABLE_MFA_GROUPS',
       'WORKGROUP',
       'ENABLE_MFA',
       'ENABLE_MFA_TRUE',
       'BACK',
       'CHECK_MORE',
       'CHANGE_NUMBER',
       'INPUT_PHONE_NUMBER',
       'USE_DIFFERENT_PHONE_NUMBER',
       'DONE',
       'PHONE_NUMBER_PLACEHOLER',
       'USE_NEW_PHONE_NUMBER']),[intl]);

  const {
    state,
    handleSendCode,
    handleClose,
    handleMFAType,
    handleWorkgroupsMore,
    handleWorkgroupsHidden,
    showGroups,
    handleShowChangeNumber,
    showChangeNumber,
    handleVerifyCode,
    handleUpdatePhoneNumber
  } = useMFALogic();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const error = useStageError(state.uiDrawerStage);
  const renderError = React.useCallback(() => {
    if (error === null || error === undefined) return null;
    if (error.open === false) return null;
    return (
      <NooshAlert
        title={error.title}
        message={error.msg}
        severity={error.severity}
        sx={{ mb: 1 }}
      />);

  }, [error]);
  const typeSelected = React.useCallback(
    (type) => state.mfaType === type,[state.mfaType]);
  const smsDisabled = m => m.type === 'sms' && m.isDisabled ===  true;
  const open = React.useMemo(
    () => state.mfa === true && state.verifyCode === false,
    [state.mfa, state.verifyCode]);
  const renderMFATitle = React.useCallback(R.cond(
    [
      [R.equals('email'), R.always(intlEmail)],
      [R.T,R.always(intlSMS)]
    ]),[intlEmail,intlSMS]);

  const renderSelectButton = (m) => {
    return (<IconButton
            disabled={smsDisabled(m)}
            onClick={() => {
              if(smsDisabled(m)) return;
              handleSendCode(m.type,false);
             } }>
            <ArrowForwardIosIcon/>
          </IconButton>);
  };
  const renderMFAMethod = R.map((m) => (
    <Box
      key={m.type}
      onClick={() => handleMFAType(m.type)}
      sx={mfaMethodStyle(typeSelected(m.type),smsDisabled(m))}
    >
      <Grid container>
        <Grid item xs={11}>
          <Typography
            sx={{pt: 3,pl: 4,}}
            gutterBottom>
            {renderMFATitle(m.type)}:
          </Typography>
          <Typography
            sx={{pl: 4,pr: 10,pb: 3,}}
            gutterBottom>
            {intl.formatMessage({id: 'SEND_TO'},
                                {sendTo: m.sendTo})}
          </Typography>
        </Grid>
        <Grid item xs={1}
              sx={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}
        >
          {renderSelectButton(m)}
        </Grid>

      </Grid>
    </Box>
  ));
  const renderWorkgroupsShort = R.map(item =>  (<ListItem sx={{padding:0}} key={item}>{item} </ListItem>));
  const renderWorkgroupsMore = R.ifElse(
    R.pipe(R.length,R.lt(3)),
    R.always((
      <ListItem
        key="check-more-workgroups"
        sx={{padding: 0}}>
        <Link
          onClick={handleWorkgroupsMore}
          sx={{ textDecoration: 'none'}}
          href="#check-more-workgroups"
        >
          {intlCheckMore}
        </Link>
      </ListItem>
     )),
    R.always(null)
  );
  const renderWorkgroups = (workgroups) => {
    if(workgroups === undefined || workgroups.length === 0) {
      return null;
    }
    const items = R.take(3,workgroups);
    return (
      <Box>
        <Typography
          component="div"
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            textDecoration: 'underline',
            fontStyle: 'italic'
          }}>
          {intlEnableMFAGroups}
        </Typography>
        <List>
          {renderWorkgroupsShort(items)}
          {renderWorkgroupsMore(workgroups)}
        </List>
      </Box>);
  };
  const renderChooseSecurity = () => {
    if (state.mfaType === 'sms' && error && error.open === true) {
      return (
        <DialogContent dividers>
          {renderError()}
        </DialogContent>);
    }
    return (
      <DialogContent dividers>
        <Typography gutterBottom>
          {intlChooseSecurityMethod}
        </Typography>

        {renderError()}
        <Stack spacing={2}>
          {renderWorkgroups(state.result.mfaWorkgroupNames)}
          {renderMFAMethod(state.result.mfaMethods)}
        </Stack>
      </DialogContent>);
  };
  const renderGroups = () => (
    <DialogContent dividers>
    <Stack>
      <Paper sx={{ overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 330 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#FAFAFA'}}>
                  <TableCell sx={{ backgroundColor: '#FAFAFA' }}>{intlWorkgroup}</TableCell>
                  <TableCell sx={{ backgroundColor: '#FAFAFA' }}>{intlEnableMFA}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                R.pipe(
                  R.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  R.map((item)=>(
                    <TableRow key={item}>
                      <TableCell>{item}</TableCell>
                      <TableCell>{intlEnableMFATrue}</TableCell>
                    </TableRow>
                  )))(state.result.mfaWorkgroupNames)
              }
            </TableBody>
          </Table>
        </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={state.result.mfaWorkgroupNames.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
      <Box sx={{
        pt: 1,
        width: '100%',
        display:'flex',
        justifyContent: 'flex-end'
    }}>
        <Button onClick={() =>{
          handleWorkgroupsHidden();
          setPage(0);
        }}>
          {intlBack}
        </Button>
      </Box>
    </Stack>
    </DialogContent>);
  const renderInputPhoneNumber = () => {
    return (
      <DialogContent dividers>
        {renderError()}
        <Typography gutterBottom>
          {intlUseNewPhoneNumber}
        </Typography>
        <Stack>
        <FormGroup sx={{mt: 1}}>
          <FormControl sx={{ mb: 1 }} variant="outlined">
          <OutlinedInput
            id="outlined-PhoneNumber"
            placeholder={intlPhoneNumberPlaceholder}
            value={state.phoneNumber}
            onChange={(event) => handleUpdatePhoneNumber(event.target.value)}
          />
        </FormControl>

      <FormControl sx={{mb: 1}}>
        <LoadingButton
          variant="contained"
          onClick={() => handleSendCode('sms',true)}
        >
          {intlDone}
        </LoadingButton>
      </FormControl>

      </FormGroup>
      </Stack>
      </DialogContent>);
  };
  const renderTitle = () => {
    if(showGroups) {
      return intlEnableMFAGroups;
    }

    return intlSecurityMethod;
  };
  const renderBody = () => {
    if(showGroups) {
      return renderGroups();
    }
    return renderChooseSecurity();
  };
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Grid container sx={{
          alignItems: 'center',
        }}>
          <Grid item xs={11}>
            <div>{renderTitle()}</div>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {renderBody()}
   </Dialog>);

}

export default MFADrawer;
