import * as R from 'ramda';
import loadable from '@loadable/component';
import en from 'locales/en_US.json'; 
import { genReduxReducer} from 'common/tool';

const LOCALES = [
  'de_CH',
  'de_DE',
  'en_AU',
  'en_EU',
  'en_GB',
  'en_US',
  'es_LA',
  'es_MX',
  'fr_CA',
  'fr_FR',
  'ja_JP',
  'zh_CN',
  'pt_BR',
  'ko_KR',
  'sv_SE',
  'no_NO',
];
const DEFAULT_LOCALE = 'en_US';

const LOCALE_SWITCH = 'LOCALE_SWITCH';
const LOCALE_LOADED = 'LOCALE_LOADED';
const LOCALE_LOADING = 'LOCALE_LOADING';

const localeActionTypes = [
  LOCALE_SWITCH,
  LOCALE_LOADED,
  LOCALE_LOADING,
];

const localeInitialState = {
  locale: 'en',
  language: 'en_US',
  messages: en,
  ready: true,
};

const createLocaleState = (language) => {
  if(language === null) return localeInitialState;
  if(language === undefined) return localeInitialState;
  if(language === DEFAULT_LOCALE) return localeInitialState;
  const locale = language.split('_')[0];
  if(R.includes(language,LOCALES)){
    return {
      locale,
      language,
      messages: {},
      ready: false
    };
  }
  return localeInitialState;
};

const localeReducer = genReduxReducer(localeActionTypes, localeInitialState);

const loadLocale = async (language) => {
  const defaultLocale = DEFAULT_LOCALE;
  const locale = language.split('_')[0];
  if (R.includes(language, LOCALES)) {
    try {
      const messages = await loadable.lib(() => import(`locales/${language}.json`)).load();
      return { locale,
               language,
               messages: messages.default };
    } catch (e) {
      try {
        const messages = await loadable.lib(() => import(`locales/${defaultLocale}.json`)).load();
        return {
          locale: locale,
          language: language,
          messages: messages.default
        };
      } catch (e) {
        console.log(e);
      }
    }
  }
  return {
    locale: defaultLocale.split('_')[0],
    language: defaultLocale,
    messages: en
  };
}

const switchLanguage = R.curry((dispatch,language) => dispatch({
  type: LOCALE_SWITCH,
  f: R.pipe(
    R.set(R.lensProp('language'),language),
    R.set(R.lensProp('ready'),false)),
}));

const loadLanguage = R.curry((dispatch,language) => dispatch({
  type: LOCALE_LOADING,
  f: (state) => {
    loadLocale(language).then((locale) =>
      dispatch({
        type: LOCALE_LOADED,
        f:R.mergeLeft({ready: true, ...locale})
      }));
    return state;
  },
}));

export {
  localeReducer,
  createLocaleState,
  switchLanguage,
  loadLanguage,
};
