import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BrandBox from 'components/layout/BrandBox';
import { useStageError } from './loginUIStage';
import { useSelector } from 'react-redux';
import NooshAlert from 'components/alert/NooshAlert';

const leftContainerStyle = (theme) => ({
  height: 'calc(100% - 30px)',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  //alignContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(0deg, #FBFBFD 33.93%, #F5F6FA 100%)',
});

const formStyle = (theme) => ({
  maxWidth: '440px',
  minHeight: '680px',
  mb: 4,
  // 
});


function LoginBox (props){
  const children = React.useMemo(() => React.Children.toArray(props.children), [props.children]);
  const state = useSelector(s => s.login);
  const error = useStageError(state.uiStage);
  const renderError = React.useCallback(() => {
    if (error === null || error === undefined) return null;
    if (error.open === false) return null;
    return (
      <NooshAlert
        title={error.title}
        message={error.msg}
        severity={error.severity}
        sx={{ mb: 1 }}
      />);

  }, [error]);
  return (
    <Box sx={leftContainerStyle}>
      <Paper sx={formStyle}>
        <BrandBox />
        <Box sx={{
          margin: {
            xs: '0px 20px 0px 20px',
            sm: '0px 60px 0px 60px'
          }
        }}>
          {renderError()}
          <Box sx={{
            width: '300px',
            minHeight: '490px'
          }}>
            {children}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default LoginBox;
