import * as R from 'ramda';
import {
  getBaseHost,
} from './tool';

const buildAPIPath = R.memoizeWith(
  R.identity,
  (path) => {
    const host = getBaseHost(window.location.hostname);
    return R.join('', ['https://auth.', host, path]);
});


const doAuth = async (body) =>{
  const authAPIPath = buildAPIPath('/api/authentication');
  return fetch(authAPIPath, { 
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: body
  });
};

const doPreAuth = async (body) => {
  const authAPIPath = buildAPIPath('/api/authentication');
  return fetch(authAPIPath, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: body
  });
}


const doResetUserId = async (email) => {
  const resetUserIdPath = buildAPIPath('/api/getUsernamesByEmail');
  const url = new URL(resetUserIdPath);
  url.search = new URLSearchParams({email}).toString();
  return fetch(url,{
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};

const doResetPassword = async (username) => {
  const resetPasswordPath = buildAPIPath('/api/getPasswordByUsername');
  const url = new URL(resetPasswordPath);
  url.search = new URLSearchParams({username}).toString();
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};

const doSendCode = async (body) => {
  const authAPIPath = buildAPIPath('/api/mfa/sendCode');
  return fetch(authAPIPath, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: body
   });
};

const doVerifyCode = async (body) => {
  const authAPIPath = buildAPIPath('/api/mfa/verifyCode');
  return fetch(authAPIPath, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: body
  });
};

const doAutoLogin = async (search) => {
  const autoLoginPath = buildAPIPath('/api/autoLogin');
  const url = autoLoginPath + search;
  return fetch(url,{
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};

const doAutoRedirect = async (params) => {
  const autoRedirectPath = buildAPIPath('/api/getRedirectURL');
  const url = new URL(autoRedirectPath);
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};

const doUpdatePassword = async (body) => {
  const resetAPIPath = buildAPIPath('/api/resetPassword');
  return fetch(resetAPIPath, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    body: body
  });
};

const doRefreshLocale = async (locale) =>{
  const baseHost  = getBaseHost(window.location.hostname);
  const theHost = R.ifElse(
    R.equals('noosh.com'),
    theHost => 'spd.' + theHost,
    R.identity
  )(baseHost);
  const refreshLocaleAPIPath = 'https://' + theHost + '/noosh/home/refreshLocale';
  const url = new URL(refreshLocaleAPIPath);
  url.search = new URLSearchParams({locale}).toString();
  return fetch(url,{
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};

const doFetchConfig = async (portal) => {
  const configAPIPath = buildAPIPath('/api/getConfig');
  const url = new URL(configAPIPath);
  url.search = new URLSearchParams({ portal }).toString();
  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
};
const doRemeberMeCheck = async () => {
  const remeberAPIPath = buildAPIPath('/api/checkRememberMe');
  const url = new URL(remeberAPIPath);
  return fetch(url,{
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
  });
}

export {
  doPreAuth,
  doAuth,
  doResetUserId,
  doResetPassword,
  doSendCode,
  doVerifyCode,
  doAutoLogin,
  doAutoRedirect,
  doUpdatePassword,
  doRefreshLocale,
  doFetchConfig,
  doRemeberMeCheck
};
