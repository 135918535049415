import React from 'react';
import Box from '@mui/material/Box';

import NooshImage from 'assets/images/noosh.svg';
import './standardLayout.css';

function BrandBox(){

  return (
    <Box sx={{
      height: '130px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 1,
    }}>
      <img className={'Logo-img'} src={NooshImage} />
    </Box>);
}

export default BrandBox;
