import React from 'react';
import { useNavigate } from 'react-router';
import {useIntl} from 'react-intl';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ForgetCommitted(props){
  const {message,onClick} = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const handleClick = React.useCallback(( ) => {
    if(onClick) onClick();
    navigate('/login');
  },[navigate,onClick]);
  return (
    <Stack sx={{with: '100%'}}>
      <Alert severity="success">
        <AlertTitle>{intl.formatMessage({id: 'SUCCESS'})}</AlertTitle>
        {intl.formatMessage({id: message,defaultMessage: message})}
      </Alert>
    <Box sx={{mt: 6,width: '100%'}}>
        <Button
          sx={{width: '100%'}}
          variant="contained"
          onClick={handleClick}
        >
          {intl.formatMessage({id: 'RETURN_TO_LOGIN'})}
        </Button>
      </Box>
    </Stack>
  );
}

export default ForgetCommitted;
