import React from 'react';
import StandardLayout from 'components/layout/StandardLayout';
import LoginForm from 'components/login/LoginForm';
import ResetForm from 'components/login/ResetForm';
import PasswordExpired from 'components/login/PasswordExpired';
import ForgetLink from 'components/layout/ForgetLink';
import LoginBox from 'components/login/LoginBox';
import ResetMessage from 'components/login/ResetMessage';
import MFADrawer from 'components/login/MFADrawer';
import VerifyCodeDrawer from 'components/login/VerifyCodeDrawer';
import TagManager from "@sooro-io/react-gtm-module";

function LoginPage(){

  const REACT_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const GA_MEASUREMENT_ID = window.noosh.ga_measurement_id || REACT_GA_MEASUREMENT_ID;
  React.useLayoutEffect(() => {
    const openArgs = {
      dataLayer: {
        event: 'login_login_page',
        eventName: 'login_login_page',
      },
    };
    if(GA_MEASUREMENT_ID) {
      TagManager.dataLayer(openArgs);
    }
  },[GA_MEASUREMENT_ID]);
  return (<React.Fragment>
            <MFADrawer/>
            <VerifyCodeDrawer/>
            <LoginBox>
              <PasswordExpired/>
              <LoginForm/>
              <ResetForm/>
              <ResetMessage/>
              <ForgetLink/>
            </LoginBox>
          </React.Fragment>);
}

export default LoginPage;
