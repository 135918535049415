import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleForgetUserIdUpdateField,
  handleForgetUserIdSuccess,
  handleForgetUserIdError,
  handleForgetUserIdLoading,
  handleForgetUserIdReset,
} from 'redux/forgetUserIdAction';

import {
  doResetUserId
} from 'common/api';
import {
  genErrorCodeToIntl,
} from 'common/intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

const errorCode = {
  404: 'NONE_EXSIST_EMAIL',
};

const errorCodeToIntl = genErrorCodeToIntl(errorCode);

function useForgetUserIdLogic() {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.forgetUserId);

  const [
    intlUnknowError,
    intlError,
  ] = React.useMemo(
    () => R.map(
      id => intl.formatMessage({ id }),
      [
        'UNKNOWN_ERROR',
        'ERROR',
      ]), [intl]);

  const handleShowError = React.useCallback(handleForgetUserIdError(reduxDispatch),[reduxDispatch]);
  const handleResetSuccess = React.useCallback(handleForgetUserIdSuccess(reduxDispatch),[reduxDispatch]);
  const handleUpdateEmail = React.useCallback(handleForgetUserIdUpdateField(reduxDispatch,'email'),[reduxDispatch]);
  const handleLoading = React.useCallback(handleForgetUserIdLoading(reduxDispatch),[reduxDispatch]);
  const handleResetState = React.useCallback(() => {
    handleForgetUserIdReset(reduxDispatch);
    navigate('/login');
  },[reduxDispatch,navigate]);
  const handleResetUserId = React.useCallback(() => {
    const email = state.email;
    handleLoading(true);
    doResetUserId(email)
      .then((response) => {
        handleLoading(false);
        if(response.ok){
          return response.text().then(text => ({text, response}));
        }else{
          return response.json().then(json => ({ json,response }));
        }
      }).then(({ text, json}) => {
        if (text) {
          const msg = R.replace('$emailAddress',email,text);
          handleResetSuccess(msg);
        } else {
          if (json.message) {
            const intlMsg = errorCodeToIntl(json.message);
            let msg = null;
            if (json.messageDict) {
              msg = intl.formatMessage({ id: intlMsg }, json.messageDict);
            } else {
              msg = intl.formatMessage({ id: intlMsg });
            }
            handleShowError({
              title: intlError,
              msg: msg
            });
          } else if (json.msg) {
            const intlMsg = errorCodeToIntl(json.msg);
            let msg = null;
            if (json.msgParams) {
              msg = intl.formatMessage({ id: intlMsg }, json.msgParams);
            } else {
              msg = intl.formatMessage({ id: intlMsg });
            }
            handleShowError({
              title: intlError,
              msg: msg
            });
          } else {
            handleShowError({
              title: intlError,
              msg: intlUnknowError
            });
          }
        }
      }).catch((error) => {
        handleLoading(false);
        handleShowError({ title: intlError, msg: intlUnknowError });
      });
  },[handleResetSuccess, handleShowError,
     intlError,intlUnknowError,
     state,intl,handleLoading]);
  return {
    state,
    handleUpdateEmail,
    handleResetUserId,
    handleResetState
  };
}

export default useForgetUserIdLogic;
