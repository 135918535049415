import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {useIntl} from 'react-intl';
import {
  handleLoginUIStage,
  handleLoginShowAlert,
  handleLoginPageLoading,
  handleLoginHandleResult,
} from 'redux/loginAction';

import {
  UI_STAGE_RESET_MESSAGE
} from './loginUIStage';

import useAuthLogic from './authLogic';

const buildAuthBody = (state) => {
  const authBody = new URLSearchParams();
  authBody.append('j_username', state.account);
  authBody.append('j_password', state.password);
  authBody.append('remember-me', state.remember);
  if (state.mfaCode !== undefined && state.mfaCode.length > 0) {
    authBody.append('mfaCode', state.mfaCode);
  }
  return authBody;
};

function useResetMessageLogic() {
  const reduxDispatch = useDispatch();
  const intl = useIntl();
  const state = useSelector(s => s.login);

  React.useEffect(() => {
    if (state.uiStage !== UI_STAGE_RESET_MESSAGE &&
        state.reset === true &&
        state.resetMessage === true) {
      handleLoginUIStage(reduxDispatch, UI_STAGE_RESET_MESSAGE);
    }
  }, [state.uiStage, state.reset,state.resetMessage,reduxDispatch]);
  const [intlUnknowError,
    intlError,
    intlWarning,
  ] = React.useMemo(
    () => R.map(id => intl.formatMessage({ id }),
      [
        'UNKNOWN_ERROR',
        'ERROR',
        'WARNING',
      ]), [intl]);
  const handleResult = React.useCallback(handleLoginHandleResult(reduxDispatch),[reduxDispatch]);
  const handleShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch, UI_STAGE_RESET_MESSAGE), [reduxDispatch]);
  const handleLoading = React.useCallback(handleLoginPageLoading(reduxDispatch, UI_STAGE_RESET_MESSAGE), [reduxDispatch]);
  const handleAuthError = React.useCallback(({ json, message, error }) => {
    handleLoading(false);
    if (error) {
      handleShowAlert('error', {
        title: intlError,
        msg: intlUnknowError
      });
    } else if (json) {
      handleResult(json);
      if (message) {
        handleShowAlert('error', {
          title: intlError,
          msg: intl.formatMessage({ id: message, defaultMessage: message }),
        });
      }
    }
  }, [handleResult, handleShowAlert,
      intlError, intlUnknowError, intl,
      handleLoading]);
  const authWithServer = useAuthLogic(handleAuthError);
  const handleAuth = React.useCallback(() => {
    const body = buildAuthBody(state);
    handleLoading(true);
    authWithServer(body);
  },[state,reduxDispatch,authWithServer,handleLoading]);

  return {
    state,
    handleAuth
  };
}

export default useResetMessageLogic;
