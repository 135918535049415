import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleLoginHandleResult,
  handleLoginCleanResult,
  handleLoginUpdateField,
  handleLoginMFASend,
  handleLoginMFAVerify,
  handleLoginDrawerStage,
  handleLoginShowAlert,
  handleLoginCleanAlert,
  handleLoginDrawerLoading,

} from 'redux/loginAction';

import {
  doVerifyCode,
  doSendCode,
} from 'common/api';

import {
  genErrorCodeToIntl,
} from 'common/intl';

import useAuthLogic from './authLogic';
import { UI_DRAWER_SEND_CODE, UI_DRAWER_VERIFY_CODE } from './loginUIStage';
import { useIntl } from 'react-intl';
import useCountDown from './countdown';

const errorCode = {
  10010: 'MFA_TYPE_IS_NOT_CORRECT',
  10002: 'DEVICEID_NOT_FOUND',
  10003: 'PERSONID_DEVICEID_NOT_MATCHED',
  10004: 'INVALID_VERIFICATION_CODE',
  10011: 'MFA_SMS_PHONE_NUM_INVALID',
  10012: 'MFA_SMS_SERVICE_NOT_AVAILABLE'
};

const verifyCodeErrorToIntl = genErrorCodeToIntl(errorCode);


function useMFALogic() {
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const state = useSelector(s => s.login);
  const [showGroups,handleShowGroups] = React.useState(false);
  const [showChangeNumber,handleShowChangeNumber] = React.useState(false);
  const [
    intlUnknowError,
    intlWarning,
    intlError,
    intlInvalidCode,
    intlInvalidPhoneNumber
  ] = React.useMemo(
    () => R.map(
      i => intl.formatMessage({ id: i, defaultMessage: i }),
      ['UNKNOWN_ERROR',
       'WARNING',
       'ERROR',
       'INVAILD_CODE',
       'INVAILD_PHONE_NUMBER'
      ]), [intl]);
  const [timeLeft,setTimeLeft] = useCountDown(0);
  React.useEffect(() => {
    if (state.mfa === true) {
      handleShowChangeNumber(false);
      if(state.verifyCode !== true &&
          state.uiDrawerStage !== UI_DRAWER_SEND_CODE)
      {
        handleShowGroups(false);
        console.log("reset mfa");
        handleLoginDrawerStage(reduxDispatch, UI_DRAWER_SEND_CODE);
      }
      if(state.verifyCode === true &&
         state.uiDrawerStage !== UI_DRAWER_VERIFY_CODE){
        handleLoginDrawerStage(reduxDispatch, UI_DRAWER_VERIFY_CODE);
        setTimeLeft(120);
      }
    }
  },[state.mfa,state.verifyCode,reduxDispatch,
     setTimeLeft,handleShowGroups,handleShowChangeNumber]);
  const handleSendCodeShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch,UI_DRAWER_SEND_CODE),[reduxDispatch]);
  const handleVerifyCodeShowAlert = React.useCallback(handleLoginShowAlert(reduxDispatch,UI_DRAWER_VERIFY_CODE),[reduxDispatch]);
  const handleClose = React.useCallback(handleLoginCleanResult(reduxDispatch), [reduxDispatch]);
  const handleMFAType = React.useCallback(handleLoginUpdateField(reduxDispatch, 'mfaType'), [reduxDispatch]);
  const handleMFASend = React.useCallback(handleLoginMFASend(reduxDispatch),[reduxDispatch]);
  const handleMFAVerify = React.useCallback(handleLoginMFAVerify(reduxDispatch),[reduxDispatch]);
  const handleResult = React.useCallback(handleLoginHandleResult(reduxDispatch), [reduxDispatch]);
  const handleUpdateRememberDevice = React.useCallback(handleLoginUpdateField(reduxDispatch,'rememberDevice'),[reduxDispatch]);
  const handleUpdatePhoneNumber = React.useCallback(handleLoginUpdateField(reduxDispatch,'phoneNumber'),[reduxDispatch]);
  const handleSendMFALoading = React.useCallback(handleLoginDrawerLoading(reduxDispatch,UI_DRAWER_SEND_CODE),[reduxDispatch]);
  const handleVerifyMFALoading = React.useCallback(handleLoginDrawerLoading(reduxDispatch,UI_DRAWER_VERIFY_CODE),[reduxDispatch]);
  const handleWorkgroupsMore = React.useCallback(() => handleShowGroups(true),[handleShowGroups]);
  const handleWorkgroupsHidden = React.useCallback(() => handleShowGroups(false),[handleShowGroups]);

  const handleUpdateCode = React.useCallback((code) => {
    handleLoginUpdateField(reduxDispatch, 'mfaCode',code);
    if (code !== '' && /^\d+$/.test(code)) {
      handleLoginCleanAlert(reduxDispatch,UI_DRAWER_VERIFY_CODE);
    }
  }, [reduxDispatch, intlError,
      intlInvalidCode,handleVerifyCodeShowAlert]);
  const handleCloseVerifyCode = React.useCallback(() => {
    handleLoginUpdateField(reduxDispatch, 'verifyCode', false);
    handleLoginUpdateField(reduxDispatch, 'mfaType','');
    handleLoginUpdateField(reduxDispatch,'mfaCode','');
  }, [reduxDispatch]);

  const handleAuthError = React.useCallback(({ json,message,error }) => {
    handleVerifyMFALoading(false);
    if (error) {
      handleVerifyCodeShowAlert('error', {
        title: intlError,
        msg: intlUnknowError
      });
    } else if (json) {
      // Error:
      // 1. MFA Error
      // 2. Should Reset
      handleResult(json);
      if (message) {
        handleVerifyCodeShowAlert('error', {
          title: intlError,
          msg: intl.formatMessage({ id: message, defaultMessage: message }),
        });
      }
    }
  }, [handleResult,handleVerifyCodeShowAlert,intl,
      intlError,intlUnknowError,handleVerifyMFALoading]);

  const authWithServer = useAuthLogic(handleAuthError);

  const handleSendCode = React.useCallback(
    (mfaType,changed) => {
      if (changed && state.phoneNumber === '') {
        handleSendCodeShowAlert('error', {
          title: intlError,
          msg: intlInvalidPhoneNumber
        });
        return;
      }
      let jbody = {
        username: state.account,
        password: state.password,
        type: mfaType
      };
      if (changed) {
        jbody = {
          ...jbody,
          phoneNumber: state.phoneNumber
        };
      }
    const body = JSON.stringify(jbody);
    handleSendMFALoading(true);
    if(state.verifyCode === true){
      setTimeLeft(120);
    }
    doSendCode(body)
      .then((response) => {
        handleSendMFALoading(false);
        if(response.ok){
          handleMFASend(mfaType);
        }else{
          response.json()
            .then((json) => {
              if (json.message) {
                const intlMsg = verifyCodeErrorToIntl(json.message);
                let msg = null;
                if (json.messageDict) {
                  msg = intl.formatMessage({ id: intlMsg }, json.messageDict);
                } else {
                  msg = intl.formatMessage({ id: intlMsg });
                }
                if(state.verifyCode){
                  handleVerifyCodeShowAlert('error', {
                    title: intlError,
                    msg: msg
                  });
                }else {
                  handleSendCodeShowAlert('error', {
                    title: intlError,
                    msg: msg
                  });
                }
              } else if (json.msg) {
                const intlMsg = verifyCodeErrorToIntl(json.msg);
                let msg = null;
                if (json.msgParams) {
                  msg = intl.formatMessage({ id: intlMsg }, json.msgParams);
                } else {
                  msg = intl.formatMessage({ id: intlMsg });
                }
                if(state.verifyCode){
                  handleVerifyCodeShowAlert('error', {
                    title: intlError,
                    msg: msg
                  });
                }else {
                  handleSendCodeShowAlert('error', {
                    title: intlError,
                    msg: msg
                  });
                }
              }else {
                if(state.verifyCode){
                  handleVerifyCodeShowAlert('error', {
                    title: intlError,
                    msg: intlUnknowError
                  });
                }else{
                  handleSendCodeShowAlert('error', {
                    title: intlError,
                    msg: intlUnknowError
                  });
                }
              }
            });
        }
      })
      .catch((error) => {
        handleSendMFALoading(false);
        if(state.verifyCode){
          handleVerifyCodeShowAlert('error', {
            title: intlError,
            msg: intlUnknowError
          });
        }else{
          handleSendCodeShowAlert('error', {
            title: intlError,
            msg: intlUnknowError
          });
        }
      });

  },[state,handleSendCodeShowAlert,
     handleMFASend,handleSendMFALoading,
     handleVerifyCodeShowAlert,
     setTimeLeft,
     intlInvalidPhoneNumber,intlError]);
  const handleVerifyCode = React.useCallback(() => {
    if (state.mfaCode === '' || !(/^\d+$/.test(state.mfaCode))) {
      handleVerifyCodeShowAlert('error', {
        title: intlError,
        msg: intlInvalidCode
      });
      return;
    }
    const body = JSON.stringify({
      username: state.account,
      password: state.password,
      mfaCode: state.mfaCode,
      rememberDevice: state.rememberDevice
    });
    const authBody = new URLSearchParams();
    authBody.append('j_username', state.account);
    authBody.append('j_password', state.password);
    authBody.append('remember-me', state.remember);
    authBody.append('mfaCode',state.mfaCode);
    authBody.append('rememberDevice', state.rememberDevice);
    authBody.append('type',state.mfaType);
    handleVerifyMFALoading(true);
    doVerifyCode(body)
      .then((response) => {
        if(response.ok){
          if(state.expired === true || state.reset === true) {
            handleVerifyMFALoading(false);
            handleMFAVerify();
          }else {
            authWithServer(authBody);
          }
        }else {
          response.json()
            .then((json) => {
              handleVerifyMFALoading(false);
              if(json.message) {
                const intlMsg = verifyCodeErrorToIntl(json.message);
                let msg = null;
                if(json.messageDict) {
                  msg = intl.formatMessage({id: intlMsg},json.messageDict);
                }else {
                  msg = intl.formatMessage({id: intlMsg});
                }
                handleVerifyCodeShowAlert('error',{
                  title: intlError,
                  msg: msg
                });
              }else   if(json.msg) {
                const intlMsg = verifyCodeErrorToIntl(json.msg);
                let msg = null;
                if(json.msgParams) {
                  msg = intl.formatMessage({id: intlMsg},json.msgParams);
                }else {
                  msg = intl.formatMessage({id: intlMsg});
                }
                handleVerifyCodeShowAlert('error',{
                  title: intlError,
                  msg: msg
                });
              }else {
                handleVerifyCodeShowAlert('error', {
                  title: intlError,
                  msg: intlUnknowError
                });
              }
          });
        }
      })
      .catch((error) => {
        handleVerifyMFALoading(false);
        handleVerifyCodeShowAlert('error', {
          title: intlError,
          msg: intlUnknowError
        });
      });
  },[state,handleMFAVerify,authWithServer,
     handleVerifyCodeShowAlert,handleSendCodeShowAlert,
     intlError,intlUnknowError,
     handleVerifyMFALoading]);

  return {
    state,
    handleClose,
    handleMFAType,
    handleUpdateCode,
    handleCloseVerifyCode,
    handleSendCode,
    handleVerifyCode,
    handleUpdateRememberDevice,
    timeLeft,
    handleWorkgroupsMore,
    handleWorkgroupsHidden,
    showGroups,
    handleShowChangeNumber,
    showChangeNumber,
    handleUpdatePhoneNumber
  };
}

export default useMFALogic;
