import React from 'react';
import {useIntl} from 'react-intl';
import * as R from 'ramda';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import useForgetUserIdLogic from './forgetUserIdLogic';
import LanguageSelectControl from 'components/intl/LanguageSelectControl';
import LoadingButton from '@mui/lab/LoadingButton';

function ForgetUserIdForm(){
  const intl = useIntl();
  const {
    state,
    handleUpdateEmail,
    handleResetUserId,
    handleResetState
  } = useForgetUserIdLogic();

  const [
    intlEmailAddress,
    intlSubmit,
    intlCancel
  ] = React.useMemo(
    () => R.map(
      id => intl.formatMessage({ id }),
      ['EMAIL_ADDRESS',
        'SUBMIT',
        'CANCEL'
      ]), [intl]);

  return (
    <FormGroup>
      <FormControl sx={{ mb: 4 }} variant="outlined">
        <InputLabel htmlFor="outlined-userid">{intlEmailAddress}</InputLabel>
        <OutlinedInput
          id="outlined-userid"
          autoFocus={true}
          label={intlEmailAddress}
          value={state.email}
          onChange={(e) => handleUpdateEmail(e.target.value)}
        />
      </FormControl>
      <LanguageSelectControl sx={{mb: 4}}/>
      <FormControl sx={{ mb: 3 }}>
        <LoadingButton
          loading={state.loading}
          loadingPosition="start"
          variant="contained"
          onClick={handleResetUserId}
          >
          {intlSubmit}
        </LoadingButton>
      </FormControl>
      <FormControl>
        <Button
          disabled={state.loading}
          variant="outlined"
          onClick={handleResetState}
        >
          {intlCancel}
        </Button>
      </FormControl>
    </FormGroup>);

}

export default ForgetUserIdForm;
