import React from 'react';
import {useIntl} from 'react-intl';
import Grid from '@mui/material/Grid';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import LoginImage from 'assets/images/login.svg';


import Copyright from 'components/layout/Copyright';

import './standardLayout.css';

const rootStyle = (theme) => ({
  width: '100%',
  height: '100vh',
  overflowY: 'hidden'
});
const gridStyle = (theme) => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto'
});
const infoContainerStyle = (theme) => ({
  backgroundColor: '#42A5F5',
  height: '100%'
});

const titleStyle = (theme) => ({
  padding: '40px 80px 0px 80px'
});

const titleLineStyle = (theme) => ({
  color: '#FFFFFF',
  fontWeight: '500',
  fontSize: '50px',
  lineHeight: '59px'
});

const contentLineStyle = (theme) => ({
  color: '#FFFFFF',
  fontWeight: 'normal',
  fontSize: '20px',
  lineHeight: '150%'
});

const imgItemStyle = (theme) => ({
  padding: '40px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 1,
});

const imgStyle = (theme)=> ({
  maxWidth: 'calc(100% - 160px)',
  maxHeight: '100%',
});
const getConfig = () => {
  const config = sessionStorage.getItem('config');
  if(config !== undefined){
    return JSON.parse(config);
  }
  return {};
};

function StandardLayout(props){
  const intl = useIntl();
  const children = React.useMemo(() => React.Children.toArray(props.children), [props.children]);
  const config = getConfig();
  return (
    <Box sx={rootStyle}>
    <Grid container spacing={0} sx={gridStyle}>
        <Grid item xs={12} md={6} >
          {children}
          <Copyright/>
      </Grid>
        <Grid item xs={0} md={6} sx={{
          display: {
            xs: 'none',
            md: 'block',
          }
        }}>
        <Stack sx={infoContainerStyle}>
          <Stack sx={titleStyle}>
            <Box sx={titleLineStyle}>
              {intl.formatMessage({id: 'WELCOME_SERVICES'},config)}
            </Box>
            <Box sx={contentLineStyle}>
              {intl.formatMessage({id: 'PARAGRAPH_ONE'})}
              <br/>
              {intl.formatMessage({id: 'PARAGRAPH_TWO'})}
            </Box>
          </Stack>
          <Box sx={imgItemStyle}>
            <Box sx={imgStyle}>
              <img className={'Login-img'} src={LoginImage} />
            </Box>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Box>);
}

export default StandardLayout;
