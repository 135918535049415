import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router';
import {
  handleLoginCleanResult
} from 'redux/loginAction';
import {
  handleForgetPasswordReset,
} from 'redux/forgetPasswordAction';
import {
  handleForgetUserIdReset,
} from 'redux/forgetUserIdAction';
function ForgetLink(props) {
  const {
    forgetUserId = true,
    forgetPassword = true,
  } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const state = useSelector(s => s.login);
  const handleURLClick = React.useCallback((url) => {
    handleLoginCleanResult(reduxDispatch)();
    handleForgetPasswordReset(reduxDispatch);
    handleForgetUserIdReset(reduxDispatch);
    navigate(url);
  },[navigate,reduxDispatch]);
  const renderForgetUserId = React.useCallback(() => {
    if(forgetUserId) {
      return (
        <Link
          onClick={() => {
            const portal = sessionStorage.getItem('portal');
            handleURLClick(`/${portal}/forget-user-id`);
          }}
          underline="none" sx={{ color: '#007DFF', cursor: 'pointer' }}>
          {intl.formatMessage({ id: 'FORGOT_YOUR_USER_ID' })}
        </Link>);
    }
    return null;
  },[forgetUserId,intl]);
  const renderForgetPassword = React.useCallback(() => {
    if(forgetPassword) {
      return (
        <Link
          onClick={() => {
            const portal = sessionStorage.getItem('portal');
            handleURLClick(`/${portal}/forget-password`);
          }}
          underline="none" sx={{ color: '#007DFF', cursor: 'pointer'}}>
          {intl.formatMessage({id: 'FORGOT_YOUR_PASSWORD' })}
        </Link>);
    }
    return null;
  },[forgetPassword,intl]);
  if (state.expired === true && state.reset === false) return null;
  return (
    <Box sx={{
      pt: 3,
      pb: 4,
      fontSize: '16px',
      fontWeight: '500',
    }}>
      <Stack spacing={3}>
        {renderForgetUserId()}
        {renderForgetPassword()}
      </Stack>
    </Box>);
}

export default ForgetLink;
