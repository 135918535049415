import React from 'react';
import { Outlet } from 'react-router';
import StandardLayout from 'components/layout/StandardLayout';
function Layout(){
  return (
    <StandardLayout>
      <Outlet/>
    </StandardLayout>);
}

export default Layout;
